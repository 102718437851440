import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileNavMenu = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en";

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item-has-children">
          <ul>
            <li>
              <Link to={process.env.PUBLIC_URL + "/"}></Link>
              {t(lang === "en" ? "home" : "الصفحة الرئيسية")}
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/shop"}></Link>
              {t(lang === "en" ? "shop" : "متجر")}
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/about"}></Link>
              {t(lang === "en" ? "About" : "عنا")}
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/contact"}>
                {t(lang === "en" ? "contact_us" : "اتصل بنا")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/partner"}>
                {t(lang === "en" ? "Partners" : "شركاء")}
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
