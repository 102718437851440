import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en";
  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              {t(lang === "en" ? "home" : "الصفحة الرئيسية")}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop"}>
              {t(lang === "en" ? "shop" : "متجر")}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/about"}>
              {t(lang === "en" ? "About" : "عنا")}
            </Link>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/contact"}>
              {t(lang === "en" ? "contact_us" : "اتصل بنا")}
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/partner"}>
              {t(lang === "en" ? "Partners" : "شركاء")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
