import useScrollTop from "../../hooks/use-scroll-top"

const ScrollToTop = () => {
  const goToWhatsapp = () => {
    const phoneNumber = "+201005580947"
    const message = "Hello! I have a question about vergnano Coffee."

    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`
    window.open(url, "_blank")
  }

  const { stick, onClickHandler } = useScrollTop()
  if (stick) {
    return (
      <>
        <button
          aria-label="Scroll to top"
          type="button"
          className="scroll-top"
          onClick={onClickHandler}
        >
          <i className="fa fa-angle-double-up"></i>
        </button>

        <button
          type="button"
          className="scroll-top"
          style={{ marginBottom: "4rem", backgroundColor: "#3b5998" }}
          onClick={() =>
            window.open(
              "https://www.facebook.com/caffevergnanoinegypt1?mibextid=LQQJ4d",
              "_blank"
            )
          }
        >
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </button>
        <button
          type="button"
          className="scroll-top"
          style={{ marginBottom: "8rem", backgroundColor: "#25d366" }}
          onClick={goToWhatsapp}
        >
          <i className="fa fa-whatsapp" aria-hidden="true"></i>
        </button>
      </>
    )
  }
  return null
}

export default ScrollToTop
