import { configureStore, combineReducers } from "@reduxjs/toolkit"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import productReducer from "./slices/product-slice"
import currencyReducer from "./slices/currency-slice"
import cartReducer from "./slices/cart-slice"
import compareReducer from "./slices/compare-slice"
import wishlistReducer from "./slices/wishlist-slice"
import { useEffect } from "react"
import { stringify } from "uuid"
import axios from "axios"
import sessionStorage from "redux-persist/es/storage/session"
import { ConsoleView } from "react-device-detect"
const persistConfig = {
  key: "flone",
  version: 1.1,
  storage,
  blacklist: ["product"],
}

const rootReducer = combineReducers({
  product: productReducer,
  currency: currencyReducer,
  cart: cartReducer,
  compare: compareReducer,
  wishlist: wishlistReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

const persistor = persistStore(store)

store.subscribe(() => {
  const currentState = store.getState()
  const Temp = currentState
  const Local = {
    cart: JSON.stringify(Temp.cart),
    wishlist: JSON.stringify(Temp.wishlist),
    compare: JSON.stringify(Temp.compare),
    currency: JSON.stringify(Temp.currency),
    _persist: JSON.stringify(Temp._persist),
  }

  // Example: Log the current state
  if (localStorage.getItem("token")) {
    axios
      .post(
        "https://verona-back-end.onrender.com/users/setLocalStorage",
        {
          localStorage: Local,
        },
        {
          headers: { Authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {})
      .catch((error) => {})
  }
  //   const currentState1 = JSON.parse(localStorage.getItem("persist:flone"))

  //   // Example: Log the current state
  //   console.log("Current State:", currentState1)

  // Add your custom side effects here
  // Example: Make an API call, dispatch additional actions, etc.
})

// Example of using useEffect in a React component
const StoreInitializer = ({ children }) => {
  useEffect(() => {
    // Your side effects here, e.g., dispatching actions, logging, etc.

    // Cleanup function (optional)
    return () => {}
  }, []) // Empty dependency array means this effect will run once, similar to componentDidMount

  return children
}

export { store, persistor, rootReducer, StoreInitializer }
