import PropTypes from "prop-types"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import axios from "axios" // Add this import
import cogoToast from "cogo-toast"
import { useTranslation } from "react-i18next"

const CustomForm = ({ status, message, onValidated }) => {
  let email
  const { t } = useTranslation()
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en"
  const submit = async () => {
    // Make the function asynchronous
    if (email && email.value.indexOf("@") > -1) {
      const formData = { email: email.value }
      try {
        const response = await axios.post(
          "https://verona-back-end.onrender.com/contactUs/subscription",
          formData
        )

        if (response.status === 200) {
          cogoToast.success("subscribed successfully", {
            position: "bottom-left",
          })

          onValidated(formData) // Assuming onValidated is a callback function provided by MailchimpSubscribe
          email.value = ""
        } else {
          cogoToast.info("Error submitting form", { position: "bottom-left" })

          console.error("Error submitting form:", response.statusText)
        }
      } catch (error) {
        console.error("Error submitting form:", error)
      }
    } else {
      cogoToast.error("Invalid e-mail", { position: "bottom-left" })
    }
  }

  return (
    <div className="subscribe-form">
      <div className="mc-form">
        <div>
          <input
            id="mc-form-email"
            className="email"
            ref={(node) => (email = node)}
            type="email"
            placeholder={t(
              lang === "en" ? "enterMail" : "ادخل البريد الإلكتروني"
            )}
          />
        </div>
        <div className="clear">
          <button className="button" onClick={submit}>
            {t(lang === "en" ? "SUBSCRIBE" : "اشترك")}
          </button>
        </div>
      </div>

      {status === "sending" && (
        <div style={{ color: "#3498db", fontSize: "12px" }}>{t("sending")}</div>
      )}
      {/* {status === "error" && (
        <div
          style={{ color: "#e74c3c", fontSize: "12px" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "#2ecc71", fontSize: "12px" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )} */}
    </div>
  )
}

const SubscribeEmail = ({ mailchimpUrl }) => {
  return (
    <div>
      <MailchimpSubscribe
        url={mailchimpUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  )
}

SubscribeEmail.propTypes = {
  mailchimpUrl: PropTypes.string,
}

export default SubscribeEmail
