import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import clsx from "clsx"
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger"
import { useTranslation } from "react-i18next"

const HeaderTop = ({ borderStyle }) => {
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en"
  const currency = useSelector((state) => state.currency)
  const { t } = useTranslation()
  const callUs = () => {
    window.location.href = "tel:+201005580947"
  }
  return (
    <div
      className={clsx(
        "header-top-wap",
        borderStyle === "fluid-border" && "border-bottom"
      )}
    >
      <LanguageCurrencyChanger currency={currency} />
      <div className="header-offer">
        <div className="same-language-currency">
          <p
            onClick={callUs}
            style={{
              cursor: "pointer",
              color: "black", // Default color
              transition: "color 0.3s", // Transition effect for smooth color change
            }}
            onMouseEnter={(e) => (e.target.style.color = "goldenrod")}
            onMouseLeave={(e) => (e.target.style.color = "black")}
          >
            {t(lang == "en" ? "CallUs" : "اتصل بنا")}{" "}
            {(lang == "en" ? "01005580947" : "٠١٠٠٥٥٨٠٩٤٧")}
          </p>
        </div>
      </div>
    </div>
  )
}

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
}

export default HeaderTop
