import styled, {keyframes} from 'styled-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;

  `;

const slideDownAnimation = keyframes`
  from {
    transform: translateY(-2rem);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
const slideUpAnimation = keyframes`
  from {
    transform: translateY(2rem);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 50%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  flex-direction: column;
  transition: all .3s;
  padding: 2rem;  
  display: flex;
  justify-content: center;
  align-items: center;
    @media (min-width: 374px) and (max-width: 768px) {
      width: 80%;
  box-shadow: none;

    }

  p{
    font-size: .9rem;
    margin-top: .3rem;
    color: rgba(0, 0, 0, 0.6);
    /* background-color: teal; */
    width: 100%;
      animation: ${props => props.isInfo1Visible ? slideDownAnimation : 'none'} 1.5s ease;
  animation: ${slideDownAnimation} 1.5s ease;

  }

  &:hover{
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);

  }
`;


export const Title = styled.h2`
  margin-bottom: 1rem;
  /* color: goldenrod; */
  font-size: 1.5rem;
display:flex;
justify-content: flex-start;
width: 100%;
  animation: ${props => props.isTitleVisible ? slideDownAnimation : 'none'} 1.5s ease-in-out;

  @media (min-width: 374px) and (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  animation: ${slideUpAnimation} 2s ease ;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
`;

export const Input = styled.input`
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const TextArea = styled.textarea`
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const Button = styled.button`
  padding: 0.5rem;
  background-color: goldenrod;
  color: black;
  border: none;
  font-weight: 500;
  margin-top: 2rem;
  width: 30%;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: black;
    color: goldenrod;
  }
`;

export const ErrorMessage = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #ffcccc;
  color: #cc0000;
  border: 1px solid #cc0000;
  border-radius: 5px;
  margin-top: 1rem;

`;

export const SuccessMessage = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #ccffcc;
  color: #006600;
  border: 1px solid #006600;
  border-radius: 5px;
`;
export const CategoriesContainer = styled.div`
  margin-bottom: 1rem;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  Select {
    width: 100%;
  }


  ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem;
    flex-wrap: wrap;



    li{
    font-size: 0.8rem;
    font-weight: 500;
margin-left: 1rem;

    

    div {
      display: flex;
      justify-content: space-between;
      // align-items: center;
box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
height: 30px;
padding: 0 .2rem;
border-radius: 5px;
margin-bottom: 0.5rem;
width: 100%;
font-size: 0.6rem;
p {
  font-weight: bold;
  margin-left: 0.5rem;
}



    }

    }
  }
`;

export const Select = styled.select`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  /* margin-left: 1rem; */
  align-items: center;
  height: 2.5rem;
  width: 10rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2rem;
`;
export const RemoveIcon = styled(CloseOutlinedIcon)`
transform: scale(0.4);
background-color: rgba(0,0,0,0.8);
border-radius: 50%;
color: white;
padding: 0.1rem;
background-color: rgba(255, 0,0,0.3);
&:hover { 
  cursor: pointer;
background-color: rgba(255, 0,0,0.6);

}

`;
export const SelectedCategories = styled.div`
width: 100%;
height: 75px;
  overflow-y: scroll;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  border: 1px solid silver;
  align-items: center;
  flex-wrap: wrap;
  /* height: 1000px; */



`;
export const NoCatsSelected = styled.div`
  font-size: .7rem;
  color: silver;
`;
export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;




