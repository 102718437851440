import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import clsx from "clsx"
import MenuCart from "./sub-components/MenuCart"
import React, { useState, useEffect } from "react"
import LogoutIcon from "@mui/icons-material/Logout"
import { useTranslation } from "react-i18next"

const IconGroup = ({ iconWhiteClass, getSortParams }) => {
  const { t } = useTranslation()
  const handleClick = (e) => {
    const nextSibling = e.currentTarget.nextSibling
    if (nextSibling) {
      nextSibling.classList.toggle("active")
    }
  }

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector("#offcanvas-mobile-menu")
    offcanvasMobileMenu.classList.add("active")
  }
  const { compareItems } = useSelector((state) => state.compare)
  const { wishlistItems } = useSelector((state) => state.wishlist)
  const { cartItems } = useSelector((state) => state.cart)
  const [searchQuery, setSearchQuery] = useState("")
  const [LoggedIn, setLoggedIn] = useState(localStorage.getItem("token"))

  useEffect(() => {
    // Update LoggedIn whenever localStorage changes
    setLoggedIn(localStorage.getItem("token"))
  }, []) // Empty dependency array means this effect runs once on component mount

  const handleChange = (e) => {
    e.preventDefault()
    const query = e.target.value

    setSearchQuery(query)
    getSortParams("search", query)
  }

  const handleSearch = (e) => {
    e.preventDefault()
    window.location.href =
      process.env.PUBLIC_URL + `/shop/search_${searchQuery}`
  }

  const handleLogout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("userData")
    localStorage.clear()
    setLoggedIn(null) // Update LoggedIn in the component state
  }
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en"

  const arabicNumberWishList = (
    wishlistItems && wishlistItems.length ? wishlistItems.length : "٠"
  ).toLocaleString("ar-EG", { useGrouping: false })
  const arabicNumbercompareItems = (
    compareItems && compareItems.length ? compareItems.length : "٠"
  ).toLocaleString("ar-EG", { useGrouping: false })
  const arabicNumbercartItems = (
    cartItems && cartItems.length ? cartItems.length : "٠"
  ).toLocaleString("ar-EG", { useGrouping: false })
  // console.log(LoggedIn);
  return (
    <>
      {lang === "en" ? (
        <div className={clsx("header-right-wrap", iconWhiteClass)}>
          <div className="same-style header-search d-none d-lg-block">
            <button className="search-active" onClick={(e) => handleClick(e)}>
              <i className="pe-7s-search" />
            </button>
            <div className="search-content">
              <form action="#">
                <input
                  type="text"
                  placeholder={t("search")}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button
                  className="button-search"
                  onClick={(e) => handleSearch(e)}
                >
                  <i className="pe-7s-search" />
                </button>
              </form>
            </div>
          </div>
          <div
            className="same-style account-setting d-none d-lg-block"
            style={{ marginLeft: "0" }}
          >
            <button
              className="account-setting-active "
              onClick={(e) => handleClick(e)}
            >
              {LoggedIn !== null && (
                <Link to={process.env.PUBLIC_URL + "/my-account"}>
                  <i className="pe-7s-user" />
                </Link>
              )}
              {LoggedIn === null && (
                <Link to={process.env.PUBLIC_URL + "/login-register"}>
                  <i className="pe-7s-user" />
                </Link>
              )}
            </button>

            {/* <div className="account-dropdown">
          <ul>
          
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"} onClick={handleLogout}>
                Logout
              </Link>
            </li>
          </ul>
        </div> */}
          </div>
          {LoggedIn !== null && (
            <div
              className="same-style account-setting d-none d-lg-block"
              style={{ marginLeft: "0" }}
            >
              <button onClick={handleLogout}>
                <Link to={process.env.PUBLIC_URL + "/login-register"}>
                  {LoggedIn !== null && (
                    <LogoutIcon style={{ transform: "translateY(-2px)" }} />
                  )}
                </Link>
              </button>
            </div>
          )}
          <div className="same-style header-compare">
            <Link to={process.env.PUBLIC_URL + "/compare"}>
              <i className="pe-7s-shuffle" />
              <span className={"count-style"}>
                {compareItems && compareItems.length ? compareItems.length : 0}
              </span>
            </Link>
          </div>
          <div className="same-style header-wishlist">
            {LoggedIn !== null ? (
              <Link to={process.env.PUBLIC_URL + "/wishlist"}>
                <i className="pe-7s-like" />
                <span className={"count-style"}>
                  {wishlistItems && wishlistItems.length
                    ? wishlistItems.length
                    : 0}
                </span>
              </Link>
            ) : (
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                <i className="pe-7s-like" />
                <span className={"count-style"}>
                  {wishlistItems && wishlistItems.length
                    ? wishlistItems.length
                    : 0}
                </span>
              </Link>
            )}
          </div>
          <div className="same-style cart-wrap d-none d-lg-block">
            <button className="icon-cart" onClick={(e) => handleClick(e)}>
              <i className="pe-7s-shopbag" />
              <span className="count-style">
                {cartItems && cartItems.length ? cartItems.length : 0}
              </span>
            </button>
            {/* menu cart */}
            <MenuCart />
          </div>
          <div className="same-style cart-wrap d-block d-lg-none">
            {LoggedIn !== null ? (
              <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
                <i className="pe-7s-shopbag" />
                <span className="count-style">
                  {cartItems && cartItems.length ? cartItems.length : 0}
                </span>
              </Link>
            ) : (
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                <i className="pe-7s-shopbag" />
                <span className="count-style">
                  {wishlistItems && wishlistItems.length
                    ? wishlistItems.length
                    : 0}
                </span>
              </Link>
            )}
          </div>
          <div className="same-style mobile-off-canvas d-block d-lg-none">
            <button
              className="mobile-aside-button"
              onClick={() => triggerMobileMenu()}
            >
              <i className="pe-7s-menu" />
            </button>
          </div>
        </div>
      ) : (
        <div className={clsx("header-right-wrap", iconWhiteClass)} dir="rtl">
          <div className="same-style header-search d-none d-lg-block">
            <button className="search-active" onClick={(e) => handleClick(e)}>
              <i className="pe-7s-search" />
            </button>
            <div className="search-content">
              <form action="#">
                <input
                  type="text"
                  placeholder={t("search")}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button
                  className="button-search"
                  onClick={(e) => handleSearch(e)}
                >
                  <i className="pe-7s-search" />
                </button>
              </form>
            </div>
          </div>
          <div
            className="same-style account-setting d-none d-lg-block"
            style={{ marginLeft: "0" }}
          >
            <button
              className="account-setting-active "
              onClick={(e) => handleClick(e)}
            >
              {LoggedIn !== null && (
                <Link to={process.env.PUBLIC_URL + "/my-account"}>
                  <i className="pe-7s-user" />
                </Link>
              )}
              {LoggedIn === null && (
                <Link to={process.env.PUBLIC_URL + "/login-register"}>
                  <i className="pe-7s-user" />
                </Link>
              )}
            </button>

            {/* <div className="account-dropdown">
          <ul>
          
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"} onClick={handleLogout}>
                Logout
              </Link>
            </li>
          </ul>
        </div> */}
          </div>
          {LoggedIn !== null && (
            <div
              className="same-style account-setting d-none d-lg-block"
              style={{ marginLeft: "0" }}
            >
              <button onClick={handleLogout}>
                <Link to={process.env.PUBLIC_URL + "/login-register"}>
                  {LoggedIn !== null && (
                    <LogoutIcon style={{ transform: "translateY(-2px)" }} />
                  )}
                </Link>
              </button>
            </div>
          )}
          <div className="same-style header-compare">
            <Link to={process.env.PUBLIC_URL + "/compare"}>
              <i className="pe-7s-shuffle" />
              <span className={lang === "en" ? "count-style" : "count-styleAr"}>
                {lang === "en"
                  ? compareItems && compareItems.length
                    ? compareItems.length
                    : 0
                  : arabicNumbercompareItems}
              </span>
            </Link>
          </div>
          <div className="same-style header-wishlist">
            {LoggedIn !== null ? (
              <Link to={process.env.PUBLIC_URL + "/wishlist"}>
                <i className="pe-7s-like" />
                <span
                  className={lang === "en" ? "count-style" : "count-styleAr"}
                >
                  {lang === "en"
                    ? wishlistItems && wishlistItems.length
                      ? wishlistItems.length
                      : 0
                    : arabicNumberWishList}
                </span>
              </Link>
            ) : (
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                <i className="pe-7s-like" />
                <span
                  className={lang === "en" ? "count-style" : "count-styleAr"}
                >
                  {console.log(lang)}
                  {console.log(arabicNumberWishList)}
                  {lang === "en"
                    ? wishlistItems && wishlistItems.length
                      ? wishlistItems.length
                      : 0
                    : arabicNumberWishList}
                </span>
              </Link>
            )}
          </div>
          <div className="same-style cart-wrap d-none d-lg-block">
            <button className="icon-cart" onClick={(e) => handleClick(e)}>
              <i className="pe-7s-shopbag" />
              <span className={lang === "en" ? "count-style" : "count-styleAr"}>
                {lang === "en"
                  ? cartItems && cartItems.length
                    ? cartItems.length
                    : 0
                  : arabicNumbercartItems}
              </span>
            </button>
            {/* menu cart */}
            <MenuCart />
          </div>
          <div className="same-style cart-wrap d-block d-lg-none">
            {LoggedIn !== null ? (
              <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
                <i className="pe-7s-shopbag" />
                <span
                  className={lang === "en" ? "count-style" : "count-styleAr"}
                >
                  {lang === "en"
                    ? cartItems && cartItems.length
                      ? cartItems.length
                      : 0
                    : arabicNumbercartItems}
                </span>
              </Link>
            ) : (
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                <i className="pe-7s-shopbag" />
                <span
                  className={lang === "en" ? "count-style" : "count-styleAr"}
                >
                  {lang === "en"
                    ? wishlistItems && wishlistItems.length
                      ? wishlistItems.length
                      : 0
                    : arabicNumberWishList}
                </span>
              </Link>
            )}
          </div>
          <div className="same-style mobile-off-canvas d-block d-lg-none">
            <button
              className="mobile-aside-button"
              onClick={() => triggerMobileMenu()}
            >
              <i className="pe-7s-menu" />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
}

export default IconGroup
