import cogoToast from "cogo-toast"
const { createSlice } = require("@reduxjs/toolkit")

const compareSlice = createSlice({
  name: "compare",
  initialState: {
    compareItems: [],
  },
  reducers: {
    addToCompare(state, action) {
      state.compareItems.push(action.payload)
      cogoToast.success("Added To compare", { position: "bottom-left" })
    },
    deleteFromCompare(state, action) {
      state.compareItems = state.compareItems.filter(
        (item) => item.product[0]._id !== action.payload
      )
      cogoToast.error("Removed From Compare", { position: "bottom-left" })
    },
    setCompare(state, action) {
      state.compareItems = JSON.parse(action.payload).compareItems
    },
  },
})

export const { addToCompare, deleteFromCompare, setCompare } =
  compareSlice.actions
export default compareSlice.reducer
