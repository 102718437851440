import { Fragment } from "react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getDiscountPrice } from "../../../helpers/product"
import { deleteFromCart } from "../../../store/slices/cart-slice"
import { useTranslation } from "react-i18next"

const MenuCart = () => {
  const dispatch = useDispatch()
  const currency = useSelector((state) => state.currency)
  const { cartItems } = useSelector((state) => state.cart)
  let cartTotalPrice = 0
  const { t, i18n } = useTranslation()
  // console.log(cartItems)
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en"
  return (
    <div
      className={
        lang === "en" ? "shopping-cart-content" : "shopping-cart-contentAr"
      }
      dir={lang === "ar" && "rtl"}
    >
      {cartItems && cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((item) => {
              const discountedPrice = getDiscountPrice(
                item.price,
                item.discount
              )
              const finalProductPrice = (
                item.price * currency.currencyRate
              ).toFixed(2)
              const finalDiscountedPrice = (
                discountedPrice * currency.currencyRate
              ).toFixed(2)

              discountedPrice != null
                ? (cartTotalPrice += item.product[0].price * item.quantity)
                : (cartTotalPrice += item.product[0].price * item.quantity)

              return (
                <li className="single-shopping-cart" key={item.cartItemId}>
                  <div className="shopping-cart-img">
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        "/product/" +
                        item.product?.[0]._id
                      }
                    >
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + item.product[0].images[0]}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          "/product/" +
                          item.product?.[0]._id
                        }
                      >
                        {" "}
                        {lang === "en" && item.product[0].name}
                        {lang === "it" && item.product[0].nameIt}
                        {lang === "ar" && item.product[0].nameFr}
                      </Link>
                    </h4>
                    <h6>
                      {lang === "en" && "Qty:"} {lang === "ar" && "الكمية"}:{" "}
                      {item.quantity}
                    </h6>
                    <span>
                      {lang === "en" && "EGP"} {item.product[0].price}
                      {lang === "ar" && "ج.م"}
                    </span>
                    {item.selectedProductColor && item.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>
                          {lang === "en" ? "Color" : "لون"}:{" "}
                          {item.selectedProductColor}
                        </span>
                        <span>
                          {lang === "en" ? "Size" : "مقاس"}:{" "}
                          {item.selectedProductSize}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() => dispatch(deleteFromCart(item.cartItemId))}
                    >
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              )
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              {t(lang === "en" ? "total" : "المجموع")} :{" "}
              <span className="shop-total">
                {lang === "en" && "EGP"} {cartTotalPrice.toFixed(2)}{" "}
                {lang === "ar" && "ج.م"}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              {t(lang === "en" ? "viewCart" : "عرض السلة")}
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              {t(lang === "en" ? "checkout" : "الدفع")}
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">
          {t(lang === "en" ? "noItemsInCart" : "لا توجد عناصر في السلة")}
        </p>
      )}
    </div>
  )
}

export default MenuCart
