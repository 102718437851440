/** @format */

import styled, { keyframes, css } from "styled-components";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { NavLink } from "react-router-dom";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
export const FacebookOutlinedIcn = styled(FacebookOutlinedIcon)`
  color: #3b5998;
  transform: scale(1.25);
`;
export const InstagramIcn = styled(InstagramIcon)`
  color: silver;
`;

export const CopyRightContainer = styled.div`
  color: white;
  font-size: 0.6rem;

  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  transform: scale(1.3);
  z-index: 100;
    margin-bottom: 1.5rem;


  @media (min-width: 374px) and (max-width: 768px) {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  span {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const CopyRightLogo = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  background-color: white;
  margin-right: 0.5rem;
`;

export const WhatsAppIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  /* background-color: rgba(37,211,102, 0.4); */
  /* padding: 3px; */
  z-index: 4;
  transform: scale(0.8);
  cursor: pointer;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: #25d366;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;


export const Title = styled.h2`
  margin: 0.7rem 0.5rem;
  color: white;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    /* Media query for screens up to 768px wide */
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    /* Media query for screens between 768px and 1200px wide */
  }

  @media (min-width: 1200px) {
    /* Media query for screens larger than 1200px wide */
  }
`;
export const SecondTitle = styled.h2`
  color: white;
  @media (max-width: 768px) {
    /* Media query for screens up to 768px wide */
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    /* Media query for screens between 768px and 1200px wide */
  }

  @media (min-width: 1200px) {
    /* Media query for screens larger than 1200px wide */
  }
`;

export const Description = styled.p`
  /* padding: 0.5rem; */
  color: silver;
`;
export const TikTokIcon = styled.img`
  width: 40px;
  height: 20px;
  margin-top:20%;
  transform: scale(1.9);
  margin-left: 0.3rem;
  /* z-index: 100; */
  cursor: pointer;
`;

export const Logo = styled.div``;

export const MenuOption = styled.li`
  font-weight: 500;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  transform: translateY(-5px);

  /* color: ${({ isActive }) => (isActive ? "white" : "inherit")}; */
  color: white;
  &:hover {
    color: goldenrod;
  }
  @media (max-width: 768px) {
    /* Media query for screens up to 768px wide */
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    /* Media query for screens between 768px and 1200px wide */
  }

  @media (min-width: 1200px) {
    /* Media query for screens larger than 1200px wide */
  }
`;

export const Menu = styled.ul`
  display: flex;
  justify-content: flex-start;
  width: 90%;
  align-items: flex-start;
  flex-direction: column;
  list-style-type: none;
  margin-right: 2rem;

  @media (max-width: 768px) {
    /* Media query for screens up to 768px wide */
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    /* Media query for screens between 768px and 1200px wide */
  }

  @media (min-width: 1200px) {
    /* Media query for screens larger than 1200px wide */
  }
`;
export const CustomNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    color: silver;
  }

  /* Apply different styles when the NavLink is active */
  &.active {
    color: goldenrod;
  }
  @media (max-width: 768px) {
    /* Media query for screens up to 768px wide */
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    /* Media query for screens between 768px and 1200px wide */
  }

  @media (min-width: 1200px) {
    /* Media query for screens larger than 1200px wide */
  }
`;

export const FacebookWrapper = styled.div`
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 100; */
  cursor: pointer;
  transform: scale(1.2);
  margin: 0.5rem;
  &:hover {
    cursor: pointer;
  }
`;


export const InstagramWrapper = styled.div`
  background: linear-gradient(
    to bottom right,
    #feda75,
    #fa7e1e,
    #d62976,
    #962fbf,
    #4f5bd5
  );
  border-radius: 50%;
  padding: 5px;
  display: flex;
  /* z-index: 100; */
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  &:hover {
    cursor: pointer;
  }
`;
export const TiktokWrapper = styled.div`
  display: flex;
margin-top:3%;
  background-color: rgba(255, 255, 255, 0.3);
  justify-content: center;
  align-items: center;
  transform: translateY(-3px) scale(.95);
  &:hover {
    cursor: pointer;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
  margin-top: 0.4rem;
  transform: scale(0.7) translateX(-3rem);
  z-index: 5000;
  /* background-color: teal; */
  @media (min-width: 374px) and (max-width: 768px) {
    width: 70%;
    margin-left: 0.6rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Left = styled.div`
  flex: 1;
  margin: 1rem 2rem;
  height: 80%;
  border-radius: 10px;
  background-color: rgba(100, 100, 100, 0.1);
  padding: 1rem;
  animation: ${({ isIntersecting }) =>
    isIntersecting
      ? css`
          ${slideDown} 0.5s ease-in-out
        `
      : "none"};
  position: relative;

  &:hover {
  }
  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
  }

  @media (min-width: 1200px) {
  }
`;

export const LanguagesContainer = styled.div`
  z-index: 100 !important;
  transform: scale(0.8);
  margin-left: 3rem;
  // maring-bottom: 3rem !important;
  @media (min-width: 374px) and (max-width: 768px) {
    margin-left: 1rem;
  }
  @media (min-width: 768px) {
  }

  select {
    padding: 0.2rem 0.4rem;
    font-size: 16px;
    border: 2px solid goldenrod;
    background-color: white;
    cursor: pointer;
    transition: border-color 0.2s;

    &:hover {
      border-color: #aaa;
    }

    &:focus {
      outline: none;
      border-color: dodgerblue;
    }
  }
`;

export const Center = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: teal;
  margin: 1rem 2rem;
  height: 80%;
  border-radius: 10px;
  background-color: rgba(100, 100, 100, 0.1);
  padding: 1rem;
  animation: ${({ isIntersecting }) =>
    isIntersecting
      ? css`
          ${slideUp} 1s ease-in-out
        `
      : "none"};
  /* box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.2); */

  &:hover {
    /* box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.3); */
  }
  @media (min-width: 374px) and (max-width: 768px) {
    padding: 0 1rem;
  }
  @media (max-width: 768px) {
    /* Media query for screens up to 768px wide */
    padding: 1rem;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    /* Media query for screens between 768px and 1200px wide */
  }

  @media (min-width: 1200px) {
    /* Media query for screens larger than 1200px wide */
  }
`;

export const PhoneIco = styled(PhoneIcon)`
  color: silver;
  margin-right: 0.5rem;
  padding-left: 0.3rem;
`;
export const EmailIco = styled(EmailIcon)`
  color: silver;
  margin-right: 0.5rem;
  padding-left: 0.3rem;
`;

export const Phone = styled.div`
  margin-left: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  cursor: pointer;
  div {
    z-index: 100;
  }
  p {
    z-index: 100;

    &:hover {
      text-decoration: underline;
    }
  }
  span {
    display: flex;
    p {
      color: white;
    }
  }
  div {
    margin-left: 2.3rem;
    color: white;
  }
`;

export const Email = styled.div`
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    z-index: 100;
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AddressIcon = styled(BusinessOutlinedIcon)`
  color: silver;
  margin-right: 0.5rem;
  padding-left: 0.3rem;
`;
export const Address = styled.div`
  color: white;
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const Right = styled.div`
  flex: 1;
  margin: 1rem 2rem;
  border-radius: 10px;
  // background-color: rgba(100, 100, 100, 0.3);
  z-index: 100;
  /* box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.2); */

  &:hover {
    /* box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.3); */
  }
  animation: ${({ isIntersecting }) =>
    isIntersecting
      ? css`
          ${slideDown} 0.5s ease-in-out
        `
      : "none"};

  height: 100%;
  padding: 1rem;

  @media (max-width: 768px) {
    /* Media query for screens up to 768px wide */
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    /* Media query for screens between 768px and 1200px wide */
    /* display:none; */
  }

  @media (min-width: 1200px) {
    /* Media query for screens larger than 1200px wide */
  }
`;

export const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  transform: scale(1.2);
  /* height: 100px; */
`;
