import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCurrency } from "../../../store/slices/currency-slice"

const MobileLangCurrChange = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const lang = localStorage.getItem("languageCode")
  ? localStorage.getItem("languageCode")
  : "en"

  const changeLanguageTrigger = e => {
    const languageCode = e.target.value;
    i18n
    .changeLanguage(languageCode)
    .then(() => {
      // Update localStorage with the new language code
      localStorage.setItem("languageCode", languageCode)
      console.log("Language updated in localStorage")
    })
    .catch((error) => {
      console.error("Error while changing language:", error)
    })
    closeMobileMenu();
  };

  const setCurrencyTrigger = e => {
    const currencyName = e.target.value;
    dispatch(setCurrency(currencyName));
    closeMobileMenu();
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div className="mobile-menu-middle">
      <div className="lang-curr-style">
        <span className="title mb-2">Choose Language</span>
        <select
          value={lang || i18n.resolvedLanguage}
          onChange={changeLanguageTrigger}
          className="form-control"
        >
          <option value="en" >English</option>
          <option value="ar" >Arabic</option>
          {/* <option value="it">Italian</option> */}
        </select>
      </div>

    </div>
  );
};

export default MobileLangCurrChange;
