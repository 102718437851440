import {
  FacebookOutlinedIcn,
  TiktokWrapper,
  FacebookWrapper,
  InstagramIcn,
  InstagramWrapper,
  TikTokIcon,
} from "../../../pages/other/footerStyled"
import tiktok from "../../../pages/other/data/tiktok.png"

const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-phone"></i>{" "}
              <a href="tel://+201005580947">(+2)01005580947 </a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              <a href="mailto:info@caffevergnano-eg.com">
                info@caffevergnano-eg.com
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <ul style={{ display: "flex", alignItems: "center" }}>
        <li>
          <a
            onClick={() =>
              window.open(
                "https://www.facebook.com/caffevergnanoinegypt1?mibextid=LQQJ4d",
                "_blank"
              )
            }
          >
            <FacebookWrapper>
              <FacebookOutlinedIcn />
            </FacebookWrapper>
          </a>
        </li>
        <li>
          <a
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "4%",
            }}
            onClick={() =>
              window.open(
                "https://www.instagram.com/caffevergnano.eg?igsh=MWRqaThxamFvend3Yw%3D%3D&utm_source=qr",
                "_blank"
              )
            }
          >
            <InstagramWrapper>
              <InstagramIcn />
            </InstagramWrapper>
          </a>
        </li>
        <li>
          <a
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "4%",
            }}
            onClick={() =>
              window.open(
                "https://www.tiktok.com/@caffevergnano.egypt?_t=8lIy3jiTPi1&_r=1",
                "_blank"
              )
            }
          >
            <TiktokWrapper>
              <TikTokIcon
                src={tiktok}
                onClick={() =>
                  window.open(
                    "https://www.tiktok.com/@caffevergnano.egypt?_t=8lIy3jiTPi1&_r=1",
                    "_blank"
                  )
                }
              />
            </TiktokWrapper>
          </a>
        </li>
      </ul>
      {/*Off Canvas Widget Social End*/}
    </div>
  )
}

export default MobileWidgets
