import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import { useTranslation } from "react-i18next";
import image from "./Coverage-Page-400x200-27.png";
const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en";
  return (
    <>
      {lang === "en" ? (
        <footer
          className={clsx(
            "footer-area",
            backgroundColorClass,
            spaceTopClass,
            // spaceBottomClass,
            extraFooterClass,
            spaceLeftClass,
            spaceRightClass
          )}
        >
          <div className={`${containerClass ? containerClass : "container"}`}>
            <div className="row">
              <div
                className={`${
                  sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
                }`}
              >
                {/* footer copyright */}
                <FooterCopyright
                  footerLogo="/assets/img/logo/logo.png"
                  spaceBottomClass="mb-30"
                />
              </div>
              <div
                className={`${
                  sideMenu ? "col-xl-2 col-sm-4" : "col-lg-3 col-sm-4"
                }`}
              >
                <div
                  className={`${
                    sideMenu
                      ? "footer-widget mb-30 ml-95"
                      : "footer-widget mb-30 "
                  }`}
                >
                  <div className="footer-title">
                    <h3>{t("QuickLINKS")}</h3>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <div className="footer-list">
                        <ul>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/"}>
                              {t("home")}
                            </Link>
                          </li>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/shop"}>
                              {t("shop")}
                            </Link>
                          </li>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/about"}>
                              {t("About")}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="footer-list">
                        <ul>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/contact"}>
                              {t("contact_us")}
                            </Link>
                          </li>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/partner"}>
                              {t("Partners")}
                            </Link>
                          </li>
                          {/* <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Orders tracking
                    </Link>
                  </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
            }`}
          >
            <div className="footer-widget mb-30 ml-30 mt-30">
              <div className="footer-title">
                <h3></h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      {t("contact_us")}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/partner"}>
                      {t("Partners")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Orders tracking
                    </Link>
                  </li> 
                </ul>
              </div>
            </div>
          </div> */}
              <div
                className={`${
                  sideMenu ? "col-xl-3 col-sm-4" : "col-lg-3 col-sm-6"
                }`}
              >
                <div
                  className={`${
                    sideMenu
                      ? "footer-widget mb-30 ml-145"
                      : "footer-widget mb-30 ml-75"
                  }`}
                >
                  <div className="footer-title">
                    <h3>{t("followUs")}</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/caffevergnanoinegypt1?mibextid=LQQJ4d"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/caffevergnano.eg?igsh=MWRqaThxamFvend3Yw%3D%3D&utm_source=qr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.tiktok.com/@caffevergnano.egypt?_t=8lIy3jiTPi1&_r=1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Tik-tok
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <img src={image} alt="" style={{ width: "250px" }}></img>
              </div>
              <div
                className={`${
                  sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
                }`}
              >
                <FooterNewsletter
                  spaceBottomClass="mb-30"
                  spaceLeftClass="ml-70"
                  sideMenu={sideMenu}
                />
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer
          dir="rtl"
          className={clsx(
            "footer-area",
            backgroundColorClass,
            spaceTopClass,
            // spaceBottomClass,
            extraFooterClass,
            spaceLeftClass,
            spaceRightClass
          )}
        >
          <div className={`${containerClass ? containerClass : "container"}`}>
            <div className="row">
              <div
                className={`${
                  sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
                }`}
              >
                {/* footer copyright */}
                <FooterCopyright
                  footerLogo="/assets/img/logo/logo.png"
                  spaceBottomClass="mb-30"
                />
              </div>
              <div
                className={`${
                  sideMenu ? "col-xl-2 col-sm-4" : "col-lg-3 col-sm-4"
                }`}
              >
                <div
                  className={`${
                    sideMenu
                      ? "footer-widget mb-30 ml-95"
                      : "footer-widget mb-30 "
                  }`}
                >
                  <div className="footer-title">
                    <h3>{t("روابط سريعة")}</h3>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <div className="footer-list">
                        <ul>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/"}>
                              {t("الصفحة الرئيسية")}
                            </Link>
                          </li>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/shop"}>
                              {t("متجر")}
                            </Link>
                          </li>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/about"}>
                              {t("عنا")}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="footer-list">
                        <ul>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/contact"}>
                              {t("اتصل بنا")}
                            </Link>
                          </li>
                          <li>
                            <Link to={process.env.PUBLIC_URL + "/partner"}>
                              {t("الشركاء")}
                            </Link>
                          </li>
                          {/* <li>
                <Link to={process.env.PUBLIC_URL + "#/"}>
                  Orders tracking
                </Link>
              </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
        className={`${
          sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
        }`}
      >
        <div className="footer-widget mb-30 ml-30 mt-30">
          <div className="footer-title">
            <h3></h3>
          </div>
          <div className="footer-list">
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + "/contact"}>
                  {t("contact_us")}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/partner"}>
                  {t("Partners")}
                </Link>
              </li>
              {/* <li>
                <Link to={process.env.PUBLIC_URL + "#/"}>
                  Orders tracking
                </Link>
              </li> 
            </ul>
          </div>
        </div>
      </div> */}
              <div
                className={`${
                  sideMenu ? "col-xl-3 col-sm-4" : "col-lg-3 col-sm-6"
                }`}
              >
                <div
                  className={`${
                    sideMenu
                      ? "footer-widget mb-30 ml-145"
                      : "footer-widget mb-30 ml-75"
                  }`}
                >
                  <div className="footer-title">
                    <h3>{t("تابعنا")}</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/caffevergnanoinegypt1?mibextid=LQQJ4d"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          فيسبوك
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/caffevergnano.eg?igsh=MWRqaThxamFvend3Yw%3D%3D&utm_source=qr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          إنستجرام
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.tiktok.com/@caffevergnano.egypt?_t=8lIy3jiTPi1&_r=1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          تيك توك
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <img src={image} alt="" style={{ width: "250px" }}></img>
              </div>
              <div
                className={`${
                  sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
                }`}
              >
                <FooterNewsletter
                  spaceBottomClass="mb-30"
                  spaceLeftClass="ml-70"
                  sideMenu={sideMenu}
                />
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
