import styled, { keyframes } from 'styled-components';
// import { MdEdit } from 'react-icons/md';


const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const slideDownAnimation = keyframes`
  from {
    transform: translateY(-2rem);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
const slideUpAnimation = keyframes`
  from {
    transform: translateY(2rem);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s linear infinite;
`;





export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-left: 4rem;
  justify-content: center;
  animation: ${slideUpAnimation} 2s ease;


    @media  (max-width: 768px) {
  transform: translateX(-24rem) translateY( -1rem) scale(.9);
  /* background-color: teal; */

    }
`;

export const UserImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  /* background-color: teal; */
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right:5rem !important;
`;

// export const EditIcon = styled(MdEdit)`
//   cursor: pointer;
//   font-size: 24px;
//   color: goldenrod;
//   transform: scale(.7);
//   background-color: rgba(0,0,0,.5);
//   border: .5px solid white;
//   padding: .5rem;
//   border-radius: 50%;
//   cursor: pointer;

//     @media  (max-width: 768px) {
//       transform: translate(3rem, 7rem) scale(.7);
//     }




// `;


export const Container = styled.div`
min-height: 50px;
z-index:100;

/* background-color: coral; */


  @media (max-width: 768px) {
    margin-top: 1rem;
  }


;

`
export const Wrapper = styled.div`
height: 100%;
display: flex;
justify-content: flex-start;
align-items: flex-start;
animation: ${slideDownAnimation} 2s ease;



/* background-color: teal; */
`
export const Form = styled.div`
display: flex;
align-items: space-evenly;
justify-content: center;
flex-direction: column;


/* background-color: teal; */


  @media  (max-width: 768px) {

  }


`
export const ImgContainer = styled.div`



`
export const ShippingContainer = styled.div`
display: flex;
margin-bottom: 1rem;
flex-direction: column;
width: 100%;
/* background-color: rgba(0,0,0,0.2); */

`
export const AddressContainer = styled.div`
display: flex;
margin-bottom: 1rem;
flex-direction: column;
width: 100%;
flex: 10;
/* background-color: rgba(0,0,0,0.2); */


`
export const ErrorBox = styled.div`
  /* background-color: teal; */
width: 80% ;
margin-top: 1rem;
height: 50px;
margin-top: 2rem;
margin-bottom: 3rem;
border-radius: 5px;
padding: 0.5rem 1rem;
background-color: rgba(255,0,0,0.1);
`;

export const InfoContainer = styled.div`
width: 100%;
font-weight: 500;
margin-bottom: 1rem;
display: flex;
justify-content: center;
align-items: center;
width: 500px;

/* background-color: coral; */


  @media  (max-width: 768px) {
    width: 350px;
    justify-content: space-between;
  }
p {
  color: silver;
  font-size: large;
}





`
export const PhoneMissing = styled.div`
  color: silver;
  font-size: .8rem;
  width: 100%;


`
export const Title = styled.div`
flex: 1;
font-size: .9rem;
  @media  (max-width: 768px) {

  }
/* background-color: teal; */

`
export const Input = styled.input`
flex:1;
/* margin-bottom: 1rem; */
display: flex;
justify-content: flex-start;
margin-left: .5rem;
border-radius: 5px;
border: 0.5px solid silver;
padding: 0.5rem 1rem;
transform: translateX(-9rem);

  @media  (max-width: 768px) {
    transform: translateX(-4rem);
  }



/* background-color: purple; */

`
export const Email = styled.div`
color: gray;
flex:2;
transform: translateX(-4rem);

  @media  (max-width: 768px) {
    transform: translateX(-1.5rem);

  }

`
export const Name = styled.div`
flex:2;
color: gray;
transform: translateX(-4rem);

/* background-color: purple; */

  @media  (max-width: 768px) {
transform: translateX(-2rem);

  }



`
export const EditBtn = styled.button`

 cursor: pointer;
font-weight: bold;
background-color: goldenrod;
border: none;
padding: .5rem 1rem;
border-radius: 5px;
color: #fff;
margin-top: 2rem;
width: 200px;



&:hover {
  background-color: black;
  color: #fff;
}


`
export const ResetBtn = styled.button`

 cursor: pointer;
font-weight: bold;
background-color: goldenrod;
border: none;
padding: 1rem 2rem;
color: black;
width: 40%;
margin-top: 2rem;

&:hover {
  background-color: black;
  color: goldenrod;
}


`
export const SaveBtn = styled.button`

 cursor: pointer;
font-weight: bold;
background-color: goldenrod;
border: none;
padding: 1rem 2rem;
color: black;
width: 40%;
margin-top: 2rem;

&:hover {
  background-color: black;
  color: goldenrod;
}

`

