import React, { useState, useEffect } from "react"
import {
  Container,
  Wrapper,
  Form,
  ShippingContainer,
  ErrorBox,
  AddressContainer,
  PhoneMissing,
  InfoContainer,
  Name,
  Input,
  Title,
  Email,
  SaveBtn,
  EditBtn,
  ResetBtn,
  UserImage,
  EditIcon,
  ImageContainer,
} from "./userDashboardStyled"
import { useSelector } from "react-redux"
import axios from "axios"
import { isMobile } from "react-device-detect"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
// import Dropzone from 'react-dropzone'; // Import the Dropzone component

// import ImageUploader from 'react-image-uploader';

const UserDashboard = ({ setOrderUserData }) => {
  const { t } = useTranslation()
  const [userData, setUserData] = useState(
    {}
    // JSON.parse(localStorage.getItem("userData")) || {}
  )
  const [imageLoading, setImageLoading] = useState(true)

  const addressData =
    userData.address && userData.address.length > 0 ? userData.address[0] : {}
  const [uploadedImage, setUploadedImage] = useState(userData?.image || null)

  const [address, setAddress] = useState({
    apartment: addressData?.apartment || null,
    street: addressData?.street || null,
    city: addressData?.city || null,
    zipCode: addressData?.zipCode || null,
    country: addressData?.country || null,
  })
  const [userImageSrc, setUserImageSrc] = useState(
    userData?.image
      ? `${userData.image}?${new Date().getTime()}`
      : "./fallback.png"
  ) // Add this line

  const handleUpdateImage = (event) => {
    setError(null)

    const selectedImage = event.target.files[0]
    setUploadedImage(selectedImage) // Set the uploaded image
    setUserImageSrc(URL.createObjectURL(selectedImage)) // Set the userImageSrc to display the preview
  }

  useEffect(() => {
    // Function to update the userData state with the latest data from localStorage
    const updateUserDataFromLocalStorage = () => {
      const updatedUserData = JSON.parse(localStorage.getItem("userData")) || {}
      setUserData(updatedUserData)
    }

    // Listen for changes in localStorage and update the userData state
    window.addEventListener("storage", updateUserDataFromLocalStorage)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", updateUserDataFromLocalStorage)
    }
  }, [])

  useEffect(() => {
    if (userData.image instanceof File) {
      const reader = new FileReader()

      reader.onload = (event) => {
        setUserImageSrc(event.target.result)
        setImageLoading(false) // Set loading to false once the image is loaded
      }

      reader.readAsDataURL(userData.image)
    }
  }, [userData.image, uploadedImage])

  const navigate = useNavigate()

  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState(null)
  const token = localStorage.getItem("token")

  useEffect(() => {
    // Function to update the userData state with the latest data from localStorage
    const updateUserDataFromLocalStorage = () => {
      const updatedUserData = JSON.parse(localStorage.getItem("userData")) || {}
      setUserData(updatedUserData)
    }

    // Listen for changes in localStorage and update the userData state
    window.addEventListener("storage", updateUserDataFromLocalStorage)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", updateUserDataFromLocalStorage)
    }
  }, [])

  // console.log(token);
  useEffect(() => {
    // Check if we're in a browser context before using sessionStorage

    const storedToken = localStorage.getItem("token")
    // setLoading(true);
    if (storedToken) {
      axios
        .get("https://verona-back-end.onrender.com/users/profile", {
          headers: {
            Authorization: `${storedToken}`,
          },
        })
        .then((response) => {
          setUserData(response.data)
        })
        .catch((error) => {})
        .finally(() => {
          // setLoading(false); // Set loading state to false when the request is complete
        })
    }
  }, [])
  const handleResetPassword = () => {
    navigate("reset")
  }

  const handleUpdateUserData = async () => {
    setError(null)

    try {
      const formData = new FormData()
      formData.append("phone", userData?.phone)
      formData.append("address", JSON.stringify(address))

      if (uploadedImage instanceof File) {
        formData.append("file", uploadedImage)
      }

      const response = await axios.put(
        `https://verona-back-end.onrender.com/users/update`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      const updatedData = response.data.user
      setOrderUserData(updatedData)
      setUserData(updatedData)
      setAddress(
        updatedData.address && updatedData.address.length > 0
          ? updatedData.address[0]
          : {}
      )
      localStorage.setItem("userData", JSON.stringify(updatedData))
      setIsEditing(false)

      setUploadedImage(updatedData?.image || null)
    } catch (error) {
      console.error(error)
    }
  }
  const addressToString = (address) => {
    // console.log(address);
    const { apartment, street, city, zipCode, country } = address
    return `${apartment || ""} ${street || ""} ${city || ""} ${zipCode || ""} ${
      country || ""
    }`.trim()
  }
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en"
  // console.log(userData);

  return (
    <Form style={{ flexDirection: isMobile ? "column" : "row-reverse" }}>
      <div className="col-lg-6 col-md-6">
        <div
          className="billing-info"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <UserImage
            src={userData.image}
            // src={
            //   userImageSrc ||
            //   (uploadedImage && URL.createObjectURL(uploadedImage))
            // }
            // Display either userImageSrc or uploadedImage
            alt="User"
          />
          {isEditing && (
            <div>
              <input
                type="file"
                id="imageInput"
                accept=".jpg, .jpeg, .png, .gif"
                onChange={(e) => {
                  handleUpdateImage(e)
                }}
                style={{ border: "none" }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-6 col-md-6">
        <div className="billing-info" style={{ display: "flex" }}>
          <label
            style={{
              marginRight: lang === "en" && "1rem",
              marginLeft: lang === "ar" && "1rem",
            }}
          >
            {t(lang === "en" ? "myName" : "اسمي")}
          </label>
          {isEditing ? (
            <input
              type="text"
              placeholder={t(lang === "en" ? "myName" : "اسمي")}
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          ) : (
            <label>{userData.name || "Empty"}</label>
          )}
        </div>
        <div className="billing-info" style={{ display: "flex" }}>
          <label
            style={{
              marginRight: lang === "en" && "1rem",
              marginLeft: lang === "ar" && "1rem",
            }}
          >
            {t(lang === "en" ? "Last Name" : "الاسم الأخير")}
          </label>
          {isEditing ? (
            <input
              type="text"
              placeholder={t(lang === "en" ? "Last Name" : "الاسم الأخير")}
              value={userData.lastName}
              onChange={(e) =>
                setUserData({ ...userData, lastName: e.target.value })
              }
            />
          ) : (
            <label>{userData.lastName || "Empty"}</label>
          )}
        </div>
        <div className="billing-info" style={{ display: "flex" }}>
          <label
            style={{
              marginRight: lang === "en" && "1rem",
              marginLeft: lang === "ar" && "1rem",
            }}
          >
            {t(lang === "en" ? "myEmail" : "بريدي الإلكتروني")}
          </label>
          {isEditing ? (
            <input
              type="text"
              disabled
              placeholder={t(lang === "en" ? "myEmail" : "بريدي الإلكتروني")}
              value={userData.email}
              readOnly
            />
          ) : (
            <label>{userData.email || "Empty"}</label>
          )}
        </div>
        <div className="billing-info" style={{ display: "flex" }}>
          <label
            style={{
              marginRight: lang === "en" && "1rem",
              marginLeft: lang === "ar" && "1rem",
            }}
          >
            {t(lang === "en" ? "myNumber" : "رقمي")}
          </label>
          {!isEditing && !userData?.phone && (
            <PhoneMissing>
              {t(lang === "en" ? "myNumber" : "رقمي")}
            </PhoneMissing>
          )}
          {isEditing ? (
            <input
              type="text"
              placeholder={t(
                lang === "en"
                  ? "Enter Your Contact Number"
                  : "أدخل رقم الاتصال الخاص بك"
              )}
              value={userData.phone}
              onChange={(e) =>
                setUserData({ ...userData, phone: e.target.value })
              }
            />
          ) : (
            <label style={{ display: "flex" }}>{userData?.phone}</label>
          )}
        </div>
        {isEditing ? (
          <>
            <EditBtn onClick={handleUpdateUserData}>
              {t(lang === "en" ? "save" : "حفظ")}
            </EditBtn>
            {error && <ErrorBox>{error}</ErrorBox>}
          </>
        ) : (
          <EditBtn onClick={() => setIsEditing(true)}>
            {t(lang === "en" ? "edit" : "تعديل")}
          </EditBtn>
        )}
      </div>
    </Form>
  )
}

export default UserDashboard
