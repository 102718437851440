import React, { useState, useEffect } from "react";
import {
  Container,
  Wrapper,
  Addbtn,
  ShoppingContainer,
  AddressContainerEditing,
  AddressesContainer,
  AddressAddingContainer,
  ErrorBox,
  AddressContainer,
  AddressEmpty,
  InfoContainer,
  Name,
  DefaultButton,
  DefaultButtonActive,
  Input,
  DeleteOutlineOutlinedIconStyled,
  Title,
  Email,
  SaveBtn,
  EditBtn,
  ResetBtn,
  DeleteBtn,
  ImageContainer,
  UserImage,
  EditIcon,
} from "./addressStyled";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
// import ReactLoading from 'react-loading';
import { useTranslation } from "react-i18next";

const Address = ({ setOrderAddress, setSelectedAddressIndex }) => {
  const [countries, setContries] = useState([]);
  useEffect(() => {
    axios
      .get("https://verona-back-end.onrender.com/country/getCountries")
      .then((response) => {
        console.log(response.data);
        console.log("test");
        setContries(response.data.data);
      })
      .catch((error) => {
        console.log("test err");

        console.log(error);
      });
  }, []);
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en";
  const navigate = useNavigate();
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData")) || {}
  );
  const [defaultAddressIndex, setDefaultAddressIndex] = useState(0);
  const [addressList, setAddressList] = useState([]); // Ensure it's an array
  const { t } = useTranslation();

  useEffect(() => {
    if (Array.isArray(userData?.address)) {
      setAddressList(userData?.address);

      // Find the index of the default address in the array
      const defaultIndex = userData.address.findIndex(
        (address) => address.default === true
      );
      setDefaultAddressIndex(defaultIndex >= 0 ? defaultIndex : 0);
    }
  }, [userData]); // Run only when userData changes
  // console.log(userData.address)

  const [isAdding, setIsAdding] = useState(false); // Added isAdding state

  // console.log(userData)
  // console.log(addressList)
  const [newAddress, setNewAddress] = useState({
    apartment: "",
    street: "",
    city: "",
    zipCode: "",
    country: "",
  });

  const handleAddAddress = () => {
    setNewAddress({
      apartment: "",
      street: "",
      city: "",
      zipCode: "",
      country: "",
    });
    setIsAdding(true); // Set isAdding to true when adding a new address
    setIsEditing(false); // Ensure isEditing is false when adding
  };

  const handleEditAddress = (index) => {
    setIsEditing(true); // Set isEditing to true when editing an address
    setIsAdding(false); // Ensure isAdding is false when editing

    // Get the address data of the selected index
    const selectedAddress = addressList[index];

    // Set the address state with the data of the selected address
    setAddress({
      apartment: selectedAddress.apartment || "",
      street: selectedAddress.street || "",
      city: selectedAddress.city || "",
      zipCode: selectedAddress.zipCode || "",
      country: selectedAddress.country || "",
    });
  };

  const handleDeleteAddress = async (index) => {
    try {
      setError(null); // Clear any previous errors

      // Make a copy of the current address list
      const updatedAddressList = [...addressList];

      // Get the address that needs to be deleted
      const deletedAddress = updatedAddressList.splice(index, 1)[0];

      // Update the address list in the state to reflect the deletion
      setAddressList(updatedAddressList);

      // Make the API call to update the backend with the modified address list
      const response = await axios.put(
        "https://verona-back-end.onrender.com/users/update",
        {
          address: updatedAddressList,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const updatedData = response.data.user;
      setUserData(updatedData);

      localStorage.setItem("userData", JSON.stringify(updatedData));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveNewAddress = async () => {
    setError(null);

    if (
      !newAddress.apartment ||
      !newAddress.street ||
      !newAddress.city ||
      !newAddress.zipCode ||
      !newAddress.country
    ) {
      setError("Please fill in all address fields.");
      return;
    }

    try {
      // Check if the new address is already in the address list
      const addressExists = addressList.some(
        (address) =>
          address.apartment === newAddress.apartment &&
          address.street === newAddress.street &&
          address.city === newAddress.city &&
          address.zipCode === newAddress.zipCode &&
          address.country === newAddress.country
      );

      if (!addressExists) {
        const updatedAddressList = [...addressList]; // Create a copy of the address list
        updatedAddressList.push(newAddress);
        const response = await axios.put(
          "https://verona-back-end.onrender.com/users/update",
          {
            address: updatedAddressList, // Use the updated address list
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(newAddress);
        const updatedData = response.data.user;

        setUserData(updatedData);
        console.log(updatedData);
        setAddressList(updatedAddressList); // Update the state with the new address list
        if (setOrderAddress)
          setOrderAddress(updatedAddressList[defaultAddressIndex]);
        localStorage.setItem("userData", JSON.stringify(updatedData)); // Update local storage
      }

      setIsEditing(false);
      setIsAdding(false);

      setNewAddress({
        apartment: "",
        street: "",
        city: "",
        zipCode: "",
        country: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const addressData =
    userData.address && userData.address.length > 0 ? userData.address[0] : {};
  const [address, setAddress] = useState({
    apartment: addressData?.apartment || null,
    street: addressData?.street || null,
    city: addressData?.city || null,
    zipCode: addressData?.zipCode || null,
    country: addressData?.country || null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Function to update the userData state with the latest data from localStorage
    const updateUserDataFromLocalStorage = () => {
      const updatedUserData =
        JSON.parse(localStorage.getItem("userData")) || {};
      setUserData(updatedUserData);
    };

    // Listen for changes in localStorage and update the userData state
    window.addEventListener("storage", updateUserDataFromLocalStorage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", updateUserDataFromLocalStorage);
    };
  }, []);

  // console.log(token);

  const handleResetPassword = () => {
    navigate("reset");
  };

  const handleUpdateUserData = async () => {
    setError(null);

    if (
      !address.apartment ||
      !address.street ||
      !address.city ||
      !address.zipCode ||
      !address.country
    ) {
      setError("Please fill in all address fields.");
      return;
    }

    try {
      const updatedAddressList = [...addressList];
      updatedAddressList[defaultAddressIndex] = address; // Update with the edited address

      const response = await axios.put(
        `https://verona-back-end.onrender.com/users/update`,
        {
          phone: userData.phone,
          address: updatedAddressList,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const updatedData = response.data.user;
      setUserData(updatedData);
      setAddressList(updatedAddressList);

      setAddress({
        apartment: addressData?.apartment || null,
        street: addressData?.street || null,
        city: addressData?.city || null,
        zipCode: addressData?.zipCode || null,
        country: addressData?.country || null,
      });
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };
  const [cities, setCities] = useState([]);
  const handleGetCities = (id) => {
    axios
      .get(`https://verona-back-end.onrender.com/country/getCitiesUser/${id}`)
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {});
  };
  const handleCancelAddingAddress = () => {
    setIsAdding(false); // Set isAdding to false
    setNewAddress({
      apartment: "",
      street: "",
      city: "",
      zipCode: "",
      country: "",
    }); // Clear the newAddress state
  };
  const handleCancelEditing = () => {
    setIsEditing(false); // Set isAdding to false
    setAddress({
      apartment: addressData?.apartment || null,
      street: addressData?.street || null,
      city: addressData?.city || null,
      zipCode: addressData?.zipCode || null,
      country: addressData?.country || null,
    });
  };

  // console.log(userData);
  // console.log(addressList)

  return (
    <>
      {!isEditing && !isAdding && (
        <>
          {addressList?.length > 0
            ? addressList?.map((address, index) => (
                <div className="entries-wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                      <div className="entries-info text-center">
                        <p>{`${address.apartment} ` || ""}</p>
                        <p>{`${address.street}, ` || ""}</p>
                        <p>{`${address.city.label}, ` || ""}</p>
                        <p>{`${address.country.label},` || ""}</p>
                        <p>{address.zipCode || ""}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                      <div className="entries-edit-delete text-center">
                        {index !== defaultAddressIndex && (
                          <button
                            className="edit"
                            onClick={() => {
                              if (setSelectedAddressIndex)
                                setSelectedAddressIndex(index);
                              setDefaultAddressIndex(index);
                              setOrderAddress(addressList[index]);
                            }}
                            style={{
                              marginRight: "1rem",
                              "border-color": "goldenrod",
                              fontSize: ".7rem",
                            }}
                          >
                            {t(lang === "en" ? "select" : "اختر")}
                          </button>
                        )}
                        {index === defaultAddressIndex && (
                          <button
                            className="edit"
                            style={{
                              marginRight: "1rem",
                              "border-color": "transparent",
                              fontSize: ".7rem",
                              backgroundColor: "transparent",
                              color: "goldenrod",
                            }}
                          >
                            {t(lang === "en" ? "selected" : "اختر")}
                          </button>
                        )}
                        <button
                          className="edit"
                          onClick={() => handleEditAddress(index)}
                          style={{ fontSize: ".7rem" }}
                        >
                          {t(lang === "en" ? "edit" : "تعديل")}
                        </button>
                        <button
                          onClick={() => handleDeleteAddress(index)}
                          style={{
                            fontSize: ".7rem",
                            marginTop:
                              window.innerWidth < "360px" ? "1rem" : "0", // Conditionally set marginTop
                          }}
                        >
                          {t(lang === "en" ? "delete" : "حذف")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : !isAdding && (
                <AddressEmpty>
                  <div> {t(lang === "en" ? "noAddress" : "لا عنوان")}</div>
                </AddressEmpty>
              )}

          <div className="billing-back-btn">
            <div className="billing-btn" style={{ justifyContent: "center" }}>
              <button onClick={handleAddAddress}>
                {t(lang === "en" ? "addAddress" : "إضافة عنوان")}
              </button>
            </div>
          </div>
        </>
      )}
      {isEditing && (
        <>
          <div className="entries-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                <div className="entries-info text-center">
                  <input
                    type="text"
                    placeholder={t(lang === "en" ? "apparment" : "شقة")}
                    value={address.apartment}
                    onChange={(e) =>
                      setAddress((prevAddress) => ({
                        ...prevAddress,
                        apartment: e.target.value,
                      }))
                    }
                  />
                  <input
                    type="text"
                    placeholder={t(lang === "en" ? "street" : "شارع")}
                    value={address.street}
                    onChange={(e) =>
                      setAddress((prevAddress) => ({
                        ...prevAddress,
                        street: e.target.value,
                      }))
                    }
                  />
                  <Select
                    value={address.country}
                    onChange={(e) => {
                      console.log(e.value);
                      setAddress((prevAddress) => ({
                        ...prevAddress,
                        country: e,
                      }));
                      handleGetCities(e.value);
                    }}
                    options={countries.map((country) => {
                      return { label: country.name, value: country._id };
                    })}
                  />
                  {/* <input
                    type="text"
                    placeholder={t(lang === "en" ? "country" : "بلد")}
                    value={address.country}
                    onChange={(e) =>
                      setAddress((prevAddress) => ({
                        ...prevAddress,
                        country: e.target.value,
                      }))
                    }
                  /> */}
                  <Select
                    value={address.city}
                    isDisabled={address.country ? false : true}
                    onChange={(e) => {
                      console.log(e);
                      setAddress((prevAddress) => ({
                        ...prevAddress,
                        city: e,
                      }));
                    }}
                    options={cities.map((country) => {
                      return { label: country.name, value: country._id };
                    })}
                  />

                  <input
                    type="number"
                    placeholder={t(lang === "en" ? "zipCode" : "الرمز البريدي")}
                    value={address.zipCode}
                    onChange={(e) =>
                      setAddress((prevAddress) => ({
                        ...prevAddress,
                        zipCode: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                <div className="entries-edit-delete text-center">
                  <button onClick={handleUpdateUserData}>
                    {t(lang === "en" ? "save" : "حفظ")}
                  </button>
                  <button onClick={handleCancelEditing}>
                    {t(lang === "en" ? "cancel" : "إلغاء")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isAdding && (
        <>
          <div className="entries-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                <div className="entries-info text-center">
                  <input
                    type="text"
                    placeholder={t(lang === "en" ? "apparment" : "شقة")}
                    value={newAddress.apartment}
                    onChange={(e) =>
                      setNewAddress((prevAddress) => ({
                        ...prevAddress,
                        apartment: e.target.value,
                      }))
                    }
                  />

                  <input
                    type="text"
                    placeholder={t(lang === "en" ? "street" : "شارع")}
                    value={newAddress.street}
                    onChange={(e) =>
                      setNewAddress((prevAddress) => ({
                        ...prevAddress,
                        street: e.target.value,
                      }))
                    }
                  />

                  {/* <input
                    type="text"
                    placeholder={t(lang === "en" ? "city" : "مدينة")}
                    value={newAddress.city}
                    onChange={(e) =>
                      setNewAddress((prevAddress) => ({
                        ...prevAddress,
                        city: e.target.value,
                      }))
                    }
                  /> */}
                  <input
                    type="number"
                    placeholder={t(lang === "en" ? "zipCode" : "الرمز البريدي")}
                    value={newAddress.zipCode}
                    onChange={(e) =>
                      setNewAddress((prevAddress) => ({
                        ...prevAddress,
                        zipCode: e.target.value,
                      }))
                    }
                  />
                  <Select
                    value={newAddress.country}
                    onChange={(e) => {
                      console.log(e);
                      setNewAddress((prevAddress) => ({
                        ...prevAddress,
                        country: e,
                      }));
                      handleGetCities(e.value);
                    }}
                    options={countries.map((country) => {
                      return { label: country.name, value: country._id };
                    })}
                  />
                  <Select
                    value={newAddress.city}
                    isDisabled={newAddress.country ? false : true}
                    onChange={(e) => {
                      localStorage.setItem("shipping", e.shipping);
                      console.log(newAddress && newAddress);
                      console.log(e);
                      setNewAddress((prevAddress) => ({
                        ...prevAddress,
                        city: e,
                      }));
                    }}
                    options={cities.map((country) => {
                      return {
                        label: country.name,
                        value: country._id,
                        shipping: country.price,
                      };
                    })}
                  />
                  {/* <input
                    type="text"
                    placeholder={t(lang === "en" ? "country" : "بلد")}
                    value={newAddress.country}
                    onChange={(e) =>
                      setNewAddress((prevAddress) => ({
                        ...prevAddress,
                        country: e.target.value,
                      }))
                    }
                  /> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                <div className="entries-edit-delete text-center">
                  <button onClick={handleSaveNewAddress}>
                    {t(lang === "en" ? "save" : "حفظ")}
                  </button>
                  <button onClick={handleCancelAddingAddress}>
                    {t(lang === "en" ? "cancel" : "إلغاء")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Address;
