import { Fragment, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { getDiscountPrice } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import Accordion from "react-bootstrap/Accordion"
import Address from "./Address.jsx"
import UserDashboard from "./UserDashboard"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  addToCart,
  decreaseQuantity,
  deleteFromCart,
  deleteAllFromCart,
} from "../../store/slices/cart-slice"

const OrderSubmitted = () => {
  let cartTotalPrice = 0

  let { pathname } = useLocation()
  const currency = useSelector((state) => state.currency)
  const { cartItems } = useSelector((state) => state.cart)
  const navigate = useNavigate()
  const token = localStorage.getItem("token")
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData")) || {}
  )
  const addressData =
    userData.address && userData.address.length > 0 ? userData.address[0] : {}
  const [address, setAddress] = useState({
    apartment: addressData?.apartment || null,
    street: addressData?.street || null,
    city: addressData?.city || null,
    zipCode: addressData?.zipCode || null,
    country: addressData?.country || null,
  })
  const [phoneAndAddressProvided, setPhoneAndAddressProvided] = useState(true) // Initially assume that both phone and address are provided
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0)
  const [fetchedCart, setFetchedCart] = useState([])
  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Check if the user has provided a phone number and address
    if (
      !userData.phone ||
      !address.apartment ||
      !address.street ||
      !address.city ||
      !address.zipCode ||
      !address.country
    ) {
      setTimeout(() => {
        setPhoneAndAddressProvided(false)
      }, 2000) // Set the state to indicate that phone or address is missing

      return // Return early without submitting the form
    }
    setPhoneAndAddressProvided(true)
    // Get the default address from userData (assuming userData.address is an array)
    const defaultAddress = userData?.address[selectedAddressIndex] || {}
    const orderData = {
      address: {
        apartment: defaultAddress?.apartment || "",
        street: defaultAddress?.street || "",
        city: defaultAddress?.city || "",
        zipCode: defaultAddress?.zipCode || "",
        country: defaultAddress?.country || "",
      },
      phone: userData?.phone || "",
      items: fetchedCart?.map((cartItem) => ({
        product: cartItem.product._id,
        quantity: cartItem.quantity,
      })),
    }


    try {
      // Send a POST request to the backend endpoint to place the order
      const response = await axios.post(
        "https://verona-back-end.onrender.com/users/order",
        orderData,
        {
          headers: {
            Authorization: token,
          },
        }
      )


      dispatch(deleteAllFromCart())

      // Navigate to the next page
      navigate("/order-submitted")
    } catch (error) {
      console.error("Error placing order:", error)
      // Handle the error if needed
    }
  }

  return (
    <Fragment>
      <SEO
        titleTemplate="Checkout"
        description="Checkout page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Order Submiited",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="checkout-area pt-95 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                    <i className="pe-7s-cart"></i>
                  </div>
                  <div className="item-empty-area__text">
                    Your Order is submited successfully <br />{" "}
                    <Link to={process.env.PUBLIC_URL + "/shop"}>
                      Continue Shopping
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  )
}

export default OrderSubmitted
