// get products
export const getProducts = (products, type, limit) => {
  // console.log(products)
  // console.log(category)
  // console.log(type)
  const finalProducts = products
  //   = category
  //   ? products.filter(
  //       product => product?.product[0]?.subCategory.name === category
  //     )
  //     : products;
  // console.log(finalProducts)

  // console.log(limit);
  if (type && type === "coffee") {
    const newProducts = finalProducts.filter(
      (single) => single?.product?.[0].style === "coffee"
    )
    // console.log(newProducts)
    return newProducts.slice(0, limit ? limit : newProducts.length)
  }
  if (type && type === "beans") {
    const newProducts = finalProducts.filter(
      (single) => single?.product[0]?.style === "beans"
    )
    // console.log(newProducts)
    return newProducts.slice(0, limit ? limit : newProducts.length)
  }
  if (type && type === "general") {
    const newProducts = finalProducts.filter(
      (single) => single?.product[0]?.style === "general"
    )
    // console.log(newProducts)
    return newProducts.slice(0, limit ? limit : newProducts.length)
  }
  return finalProducts.slice(0, limit ? limit : finalProducts.length)
}

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null
}

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
  let productInCart =cartItems&& cartItems.find(
    (single) =>
      single?.product[0]?._id === product?.product[0]?._id &&
      (single.selectedProductColor
        ? single.selectedProductColor === color
        : true) &&
      (single.selectedProductSize ? single.selectedProductSize === size : true)
  )
  if (cartItems&&cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      return cartItems.find(
        (single) =>
          single.id === product.id &&
          single.selectedProductColor === color &&
          single.selectedProductSize === size
      ).quantity
    } else {
      return cartItems.find((single) => product.id === single.id).quantity
    }
  } else {
    return 0
  }
}

export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock
  } else {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock
  }
}

//get products based on category
export const getSortedProducts = (
  products,
  sortType,
  sortValue,
  sortValue2
) => {
  // console.log(sortType);
  // console.log(sortValue);

  if (products && sortType && sortValue) {
    if (sortType === "category") {
      return products.filter(
        (product) => product?.product[0].subCategory.category.name === sortValue
      )
    }
    if (sortType === "subCategory") {
      return products.filter(
        (product) => product?.product[0].subCategory.name === sortValue
      )
    }
    if (sortType === "tag") {
      return products.filter(
        (product) => product.tag.filter((single) => single === sortValue)[0]
      )
    }
    if (sortType === "color") {
      return products.filter(
        (product) =>
          product.product[0].color && product.product[0].color === sortValue
      )
    }
    if (sortType === "price") {
      // console.log('price');
      return products.filter((product) => {
        const productPrice = product?.product[0]?.price
        return (
          productPrice &&
          productPrice >= sortValue[0] &&
          productPrice <= sortValue[1]
        )
      })
    }

    if (sortType === "search") {
      const searchRegex = new RegExp(sortValue, "i") // Case-insensitive search
      return products.filter((product) => {
        const productName = product?.product[0]?.name
        const productNameAr = product?.product[0]?.nameAr

        return (
          (productName && productName.match(searchRegex)) ||
          (productNameAr && productNameAr.match(searchRegex))
        )
      })
    }

    if (sortType === "filterSort") {
      let sortProducts = [...products]
      if (sortValue === "default") {
        return sortProducts
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          return b.product[0].price - a.product[0].price
        })
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts.sort((a, b) => {
          return a.product[0].price - b.product[0].price
        })
      }
    }
  }
  return products
}

// get individual element
const getIndividualItemArray = (array) => {
  let individualItemArray = array.filter(function (v, i, self) {
    return i === self.indexOf(v)
  })
  return individualItemArray
}

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = []
  // console.log(products);
  products &&
    products?.forEach((product) => {
      const category = product._id
      if (category && !productCategories.includes(category)) {
        productCategories.push(category)
      }
    })
  // console.log(productCategories)
  const individualProductCategories = getIndividualItemArray(productCategories)
  return productCategories
}
export const getIndividualSubCategories = (products) => {
  let productSubCategories = []

  products.forEach((product) => {
    const subCategory = product.product[0].subCategory
    if (subCategory) {
      const uniqueIdentifier = JSON.stringify(subCategory)
      if (!productSubCategories.includes(uniqueIdentifier)) {
        productSubCategories.push(uniqueIdentifier)
      }
    }
  })

  const uniqueSubCategories = productSubCategories.map((identifier) => {
    const temp = JSON.parse(identifier)
    // console.log(JSON.parse(identifier))

    return {
      category: temp.category,
      name: temp.name,
      nameIt: temp.nameIt,
      nameFr: temp.nameFr,
      // Add other properties if necessary
    }
  })

  return uniqueSubCategories
}

// get individual tags
export const getIndividualTags = (products) => {
  let productTags = []
  products &&
    products.map((product) => {
      return (
        product.tag &&
        product.tag.map((single) => {
          return productTags.push(single)
        })
      )
    })
  const individualProductTags = getIndividualItemArray(productTags)
  return individualProductTags
}

// get individual colors
export const getIndividualColors = (products) => {
  let productColors = []
  products &&
    products.forEach((product) => {
      const color = product.product[0].color
      if (color && !productColors.includes(color)) {
        productColors.push(color)
      }
      // console.log(productColors);
    })
  return productColors
}
export const getIndividualPrices = (products) => {
  let productPrices = []
  products &&
    products.forEach((product) => {
      const price = product.product[0].price
      if (price && !productPrices.includes(price)) {
        productPrices.push(price)
      }
      // console.log(productColors);
    })
  return productPrices
}

// get individual sizes
export const getProductsIndividualWeights = (products) => {
  let productWeights = []
  products &&
    products.forEach((product) => {
      const weight = product?.product[0]?.weight
      if (weight && !productWeights.includes(weight)) {
        productWeights.push(weight)
      }
      // console.log(productWeights);
    })
  return productWeights
}

// get product individual sizes
export const getIndividualSizes = (product) => {
  let productSizes = []
  product.variation &&
    product.variation.map((singleVariation) => {
      return (
        singleVariation.size &&
        singleVariation.size.map((singleSize) => {
          return productSizes.push(singleSize.name)
        })
      )
    })
  const individualSizes = getIndividualItemArray(productSizes)
  return individualSizes
}

export const setActiveSort = (e) => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
  )
  filterButtons.forEach((item) => {
    item.classList.remove("active")
  })
  e.currentTarget.classList.add("active")
}

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button")
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active")
  })
  e.currentTarget.classList.add("active")
}

export const toggleShopTopFilter = (e) => {
  const shopTopFilterWrapper = document.querySelector("#product-filter-wrapper")
  shopTopFilterWrapper.classList.toggle("active")
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null
  } else {
    shopTopFilterWrapper.style.height = shopTopFilterWrapper.scrollHeight + "px"
  }
  e.currentTarget.classList.toggle("active")
}
