import { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import {
  Container,
  Title,
  Form,
  Label,
  Input,
  TextArea,
  Button,
  ErrorMessage,
  SuccessMessage,
  Wrapper,
  CategoriesContainer,
  Select,
  RemoveIcon, // Replace 'RemoveIcon' with the icon component you're using
  SelectedCategories,
  NoCatsSelected,
  FormContainer,
} from "./partnersStyled";

import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";

const Partner = () => {
  const { t, i18n } = useTranslation();
  let { pathname } = useLocation();
  const [products, setProducts] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);

  const [filteredProducts, setFilteredProducts] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleProductRemoval = (productId) => {
    setSelectedProducts(
      selectedProducts.filter((product) => product.product[0]._id !== productId)
    );
  };

  const handleCategoryRemoval = (categoryId) => {
    const x = selectedCategories.filter((category) => category !== categoryId);
    setSelectedCategories(x);
    if (x.length === 0) {
      setFilteredProducts(products);
      setSelectedProducts([]);
      return;
    }

    // const selectedCategoryIds = getSelectedCategoryIds()

    // Filter products based on the remaining selected category IDs
    const temp =
      x.length > 0
        ? products.filter((product) =>
            x.includes(product.product[0].subCategory.category._id)
          )
        : [];
    setFilteredProducts(temp);
    setSelectedProducts([]);
  };

  const handleChangeCategories = (e) => {
    const selectedOption = e.target.value;

    const isAlreadySelected = selectedCategories.includes(selectedOption);
    let temp;
    if (!isAlreadySelected) {
      setSelectedCategories([...selectedCategories, selectedOption]);
      temp = [...selectedCategories, selectedOption];
    } else {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== selectedOption)
      );
      temp = selectedCategories.filter(
        (category) => category !== selectedOption
      );
    }

    let x = products;

    if (temp && temp.length > 0) {
      x = x.filter((product) => {
        // Check if the product's category ID is in the selectedCategories array
        const productCategoryId = product.product[0].subCategory.category._id;

        return temp.includes(productCategoryId);
      });
    }
    setFilteredProducts(x);
    setSelectedProducts([]);
  };

  const handleChangeProducts = (e) => {
    const selectedProductID = e.target.value;

    const isAlreadySelected = selectedProducts.some(
      (product) => product._id === selectedProductID
    );

    if (!isAlreadySelected) {
      const selectedProduct = products.find(
        (prod) => prod.product[0]._id === selectedProductID
      );
      if (selectedProduct) {
        setSelectedProducts([...selectedProducts, selectedProduct]);
      }
    } else {
      setSelectedProducts(
        selectedProducts.filter((product) => product._id !== selectedProductID)
      );
    }
  };

  // useEffect(() => {
  //   // Filter products based on the selected categories
  //   let filteredProducts = products

  //   if (selectedCategories && selectedCategories.length > 0) {
  //     filteredProducts = products.filter((product) => {
  //       const productCategoryId = product.product[0].subCategory.category
  //       return selectedCategories.includes(productCategoryId)
  //     })
  //   }

  //   // Update the filteredProducts state
  //   setFilteredProducts(filteredProducts)
  // }, [selectedCategories, products]) // Add selectedCategories and products as dependencies

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://verona-back-end.onrender.com/products/categories"
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://verona-back-end.onrender.com/products/all"
        );
        setProducts(response.data.products);
        setFilteredProducts(response.data.products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const getSelectedCategoryIds = () => {
    return selectedCategories.map((category) => category._id);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.phone.length != 11) {
      cogoToast.error("Phone must be 11 digits!", { position: "bottom-left" });
      return;
    }

    if (selectedProducts.length < 1) {
      cogoToast.error("Select atleast 1 product!", { position: "bottom-left" });
      return;
    }

    let productsNames = selectedProducts.map(
      (product) => product.product[0].name
    );

    const payload = {
      ...formData,
      products: productsNames,
    };

    try {
      const response = await axios.post(
        "https://verona-back-end.onrender.com/contactUs/businessToBusiness",
        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json", // Set the content type header to indicate JSON data
          },
        }
      );
      if (response.status === 200) {
        cogoToast.success("submitted successfully", {
          position: "bottom-left",
        });
        setFormData({
          // Clear the form on successful submission
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        setSelectedCategories([]);
        setSelectedProducts([]);
        setFilteredProducts(products);
      } else {
        cogoToast.info("Error submitting form", { position: "bottom-left" });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en";
  return (
    <Fragment>
      <SEO
        titleTemplate="Contact"
        description="Contact page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            {
              label: t(lang === "en" ? "home" : "الصفحة الرئيسية"),
              path: process.env.PUBLIC_URL + "/",
            },
            {
              label: t(lang === "en" ? "partners" : "شركاء"),
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        {lang === "en" ? (
          <div className="contact-area pt-100 pb-100">
            <div className="container">
              <div className="custom-row-2">
                <div className="col-12 col-lg-12 col-md-12">
                  <div className="contact-form">
                    <div className="contact-title mb-30">
                      <h2>{t("becomePartner")}</h2>
                    </div>
                    <form
                      className="contact-form-style"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <input
                            name="name"
                            value={formData.name}
                            placeholder={t("name")}
                            type="text"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <input
                            value={formData.phone}
                            name="phone"
                            placeholder={t("number")}
                            type="number"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-12">
                          <input
                            value={formData.email}
                            required
                            name="email"
                            placeholder={t("email")}
                            type="email"
                            onChange={handleChange}
                          />
                        </div>

                        <CategoriesContainer>
                          <Select
                            value={selectedCategories}
                            onChange={handleChangeCategories}
                            style={{ border: "1px solid gray" }}
                          >
                            <option value="">{t("selectCat")}</option>
                            {categories?.map((category) => (
                              <option key={category._id} value={category._id}>
                                {i18n.resolvedLanguage === "en" &&
                                  category.name}
                                {i18n.resolvedLanguage === "it" &&
                                  category.nameIt}
                                {i18n.resolvedLanguage === "fn" &&
                                  category.nameFr}
                              </option>
                            ))}
                          </Select>
                          <SelectedCategories>
                            {/* <h4>Selected Categories:</h4>  */}
                            <ul>
                              {selectedCategories?.length > 0 &&
                                selectedCategories.map((categoryId) => {
                                  const category = categories.find(
                                    (cat) => cat._id === categoryId
                                  );
                                  return (
                                    <li key={categoryId}>
                                      {category && (
                                        <div style={{ height: "auto" }}>
                                          <p style={{ margin: "5px" }}>
                                            {category.name}
                                          </p>
                                          <RemoveIcon
                                            onClick={() =>
                                              handleCategoryRemoval(categoryId)
                                            }
                                            // Add any required props for the remove icon component
                                          />
                                        </div>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                            {selectedCategories?.length === 0 && (
                              <NoCatsSelected>
                                {t("noCatsAdded")}
                              </NoCatsSelected>
                            )}
                          </SelectedCategories>
                        </CategoriesContainer>
                        {/* Add the products section */}
                        <CategoriesContainer>
                          <Select
                            value={selectedProducts}
                            onChange={handleChangeProducts}
                            style={{ border: "1px solid gray" }}
                          >
                            <option value="">{t("selectProds")}</option>
                            {filteredProducts?.map((product) => (
                              <option
                                key={product.product[0]._id}
                                value={product.product[0]._id}
                              >
                                {product.product[0].name}
                                {i18n.resolvedLanguage === "en" &&
                                  product.product[0].name}
                                {i18n.resolvedLanguage === "it" &&
                                  product.product[0].nameIt}
                                {i18n.resolvedLanguage === "fn" &&
                                  product.product[0].nameFr}
                              </option>
                            ))}
                          </Select>
                          <SelectedCategories>
                            <ul>
                              {selectedProducts?.length > 0 &&
                                selectedProducts.map((selectedProduct) => (
                                  <li key={selectedProduct.product[0]._id}>
                                    <div style={{ height: "auto" }}>
                                      <p style={{ margin: "5px" }}>
                                        {" "}
                                        {selectedProduct.product[0].name}
                                      </p>

                                      <RemoveIcon
                                        onClick={() =>
                                          handleProductRemoval(
                                            selectedProduct.product[0]._id
                                          )
                                        }
                                      />
                                    </div>
                                  </li>
                                ))}
                            </ul>
                            {selectedProducts?.length === 0 && (
                              <NoCatsSelected>{t("noProds")}</NoCatsSelected>
                            )}
                          </SelectedCategories>
                        </CategoriesContainer>

                        <div className="col-lg-12">
                          <textarea
                            required
                            name="message"
                            placeholder={t("yourMsg")}
                            defaultValue={""}
                            value={formData.message}
                            onChange={handleChange}
                          />
                          <button className="submit" type="submit">
                            {t("send")}
                          </button>
                        </div>
                      </div>
                    </form>
                    <p className="form-message" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div dir="rtl" className="contact-area pt-100 pb-100">
            <div className="container">
              <div className="custom-row-2">
                <div className="col-12 col-lg-12 col-md-12">
                  <div className="contact-form">
                    <div className="contact-title mb-30">
                      <h2>{t("كن شريكًا")}</h2>
                    </div>
                    <form
                      className="contact-form-style"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <input
                            name="name"
                            value={formData.name}
                            placeholder={t("الاسم")}
                            type="text"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <input
                            value={formData.phone}
                            name="phone"
                            placeholder={t("رقم")}
                            type="number"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-12">
                          <input
                            value={formData.email}
                            required
                            name="email"
                            placeholder={t("البريد الإلكتروني")}
                            type="email"
                            onChange={handleChange}
                          />
                        </div>

                        <CategoriesContainer>
                          <Select
                            value={selectedCategories}
                            onChange={handleChangeCategories}
                            style={{ border: "1px solid gray" }}
                          >
                            <option value="">{t("اختر الفئة")}</option>
                            {categories?.map((category) => (
                              <option key={category._id} value={category._id}>
                                {category.nameFr}
                              </option>
                            ))}
                          </Select>
                          <SelectedCategories>
                            {/* <h4>Selected Categories:</h4>  */}
                            <ul>
                              {selectedCategories?.length > 0 &&
                                selectedCategories.map((categoryId) => {
                                  const category = categories.find(
                                    (cat) => cat._id === categoryId
                                  );
                                  return (
                                    <li key={categoryId}>
                                      {category && (
                                        <div style={{ height: "auto" }}>
                                          <p style={{ margin: "5px" }}>
                                            {category.name}
                                          </p>
                                          <RemoveIcon
                                            onClick={() =>
                                              handleCategoryRemoval(categoryId)
                                            }
                                            // Add any required props for the remove icon component
                                          />
                                        </div>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                            {selectedCategories?.length === 0 && (
                              <NoCatsSelected>
                                {t("لم يتم إضافة أي فئات")}
                              </NoCatsSelected>
                            )}
                          </SelectedCategories>
                        </CategoriesContainer>
                        {/* Add the products section */}
                        <CategoriesContainer>
                          <Select
                            value={selectedProducts}
                            onChange={handleChangeProducts}
                            style={{ border: "1px solid gray" }}
                          >
                            <option value="">{t("اختر المنتجات")}</option>
                            {filteredProducts?.map((product) => (
                              <option
                                key={product.product[0]._id}
                                value={product.product[0]._id}
                              >
                                {product.product[0].name}

                                {i18n.resolvedLanguage === "ar" &&
                                  product.product[0].nameFr}
                              </option>
                            ))}
                          </Select>
                          <SelectedCategories>
                            <ul>
                              {selectedProducts?.length > 0 &&
                                selectedProducts.map((selectedProduct) => (
                                  <li key={selectedProduct.product[0]._id}>
                                    <div style={{ height: "auto" }}>
                                      <p style={{ margin: "5px" }}>
                                        {" "}
                                        {selectedProduct.product[0].name}
                                      </p>

                                      <RemoveIcon
                                        onClick={() =>
                                          handleProductRemoval(
                                            selectedProduct.product[0]._id
                                          )
                                        }
                                      />
                                    </div>
                                  </li>
                                ))}
                            </ul>
                            {selectedProducts?.length === 0 && (
                              <NoCatsSelected>
                                {t("لا يوجد منتجات")}
                              </NoCatsSelected>
                            )}
                          </SelectedCategories>
                        </CategoriesContainer>

                        <div className="col-lg-12">
                          <textarea
                            required
                            name="message"
                            placeholder={t("رسالتك")}
                            defaultValue={""}
                            value={formData.message}
                            onChange={handleChange}
                          />
                          <button className="submit" type="submit">
                            {t("إرسال")}
                          </button>
                        </div>
                      </div>
                    </form>
                    <p className="form-message" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </LayoutOne>
    </Fragment>
  );
};

export default Partner;
