import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import { store } from "./store/store"
import PersistProvider from "./store/providers/persist-provider"
import { setProducts } from "./store/slices/product-slice"
import products from "./data/products.json"
import "animate.css"
import "swiper/swiper-bundle.min.css"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import "./assets/scss/style.scss"
import "./i18n"
import axios from "axios" // Import Axios

// console.log(products)

axios
  .get("https://verona-back-end.onrender.com/products/all")
  .then((response) => {
    // Dispatch the products to Redux store once fetched
    // console.log(response.data.products);
    store.dispatch(setProducts(response.data.products))
  })
  .catch((error) => {
    console.error("Error fetching products data:", error)
  })
// store.dispatch(setProducts(products));

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <PersistProvider>
      <App />
    </PersistProvider>
  </Provider>
)
