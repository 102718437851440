import { Fragment, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import axios from "axios"

import cogoToast from "cogo-toast"

const ForgotForm = () => {
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [formData, setFormData] = useState({
    email: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        "https://verona-back-end.onrender.com/users/forgot",
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json", // Set the content type header to indicate JSON data
          },
        }
      )
      if (response.status === 200) {
        cogoToast.success("A reset link has been sent to your email", {
          position: "bottom-left",
        })
        setFormData({
          // Clear the form on successful submission

          email: "",
        })
        navigate("/forgot-verify")
      } else {
        cogoToast.info("Error submitting form", { position: "bottom-left" })
      }
    } catch (error) {
      console.error(error)
      cogoToast.info(error.response.data.error, { position: "bottom-left" })
    }
  }

  return (
    <Fragment>
      <SEO
        titleTemplate="Contact"
        description="Contact page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Forgot", path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        <div className="contact-area pt-100 pb-100">
          <div className="container">
            <div className="custom-row-2">
              <div className="col-12 col-lg-12 col-md-12">
                <div className="contact-form">
                  <div className="contact-title mb-30">
                    <h2>Submit the form to reset your password</h2>
                  </div>
                  <form className="contact-form-style" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <input
                          value={formData.email}
                          required
                          name="email"
                          placeholder="Email*"
                          type="email"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-12">
                        <button className="submit" type="submit">
                          SEND
                        </button>
                      </div>
                    </div>
                  </form>
                  <p className="form-message" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  )
}

export default ForgotForm
