import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { setCurrency } from "../../../store/slices/currency-slice"

const LanguageCurrencyChanger = ({ currency }) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const changeLanguageTrigger = (e) => {
    const languageCode = e.target.value
    console.log(languageCode)

    // Assuming i18n.changeLanguage() is asynchronous
    i18n
      .changeLanguage(languageCode)
      .then(() => {
        // Update localStorage with the new language code
        localStorage.setItem("languageCode", languageCode)
        console.log("Language updated in localStorage")
      })
      .catch((error) => {
        console.error("Error while changing language:", error)
      })
  }

  const setCurrencyTrigger = (e) => {
    const currencyName = e.target.value
    dispatch(setCurrency(currencyName))
  }
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en"
  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency language-style">
        <span>
          {lang === "en"
            ? "English"
            : lang === "ar"
            ? "عربي"
            : lang === "it"
            ? "Italian"
            : ""}{" "}
          <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="en" onClick={(e) => changeLanguageTrigger(e)}>
                English
              </button>
            </li>
            <li>
              <button value="ar" onClick={(e) => changeLanguageTrigger(e)}>
                Arabic
              </button>
            </li>
            {/* <li>
              <button value="it" onClick={e => changeLanguageTrigger(e)}>
                Italian
              </button>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

LanguageCurrencyChanger.propTypes = {
  currency: PropTypes.shape({}),
}

export default LanguageCurrencyChanger
