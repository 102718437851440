import PropTypes from "prop-types"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Link } from "react-router-dom"
import crumb from "./crumb.jpeg"

const BreadcrumbWrap = ({ pages }) => {
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en"
  return (
    <div
      dir={lang === "en" ? "ltr" : "rtl"}
      className="breadcrumb-area pt-35 pb-35 bg-gray-3"
      style={{
        background: `url(${crumb}) center/cover no-repeat`, // Replace "your-image-url.jpg" with your image URL
        position: "relative",
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)", // Adjust the overlay color and opacity as needed
        }}
      ></div> */}
      <div className={lang === "en" ? "container" : "container slash"}>
        <Breadcrumb>
          {pages?.map(({ path, label }, i) =>
            i !== pages.length - 1 ? (
              <Breadcrumb.Item
                key={label}
                linkProps={{ to: path }}
                style={{ color: "#e6e5e5", zIndex: "99" }}
                linkAs={Link}
              >
                {label}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item
                key={label}
                active
                style={{ color: "white", zIndex: "99" }}
              >
                {label}
              </Breadcrumb.Item>
            )
          )}
        </Breadcrumb>
      </div>
    </div>
  )
}

BreadcrumbWrap.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default BreadcrumbWrap
