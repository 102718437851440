import styled, {keyframes} from 'styled-components'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';



import { MdEdit } from 'react-icons/md';


const slideDownAnimation = keyframes`
  from {
    transform: translateY(-2rem);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
const slideUpAnimation = keyframes`
  from {
    transform: translateY(2rem);
    opacity: 1;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const DeleteOutlineOutlinedIconStyled = styled(DeleteOutlineOutlinedIcon)`
color: rgba(255, 0, 0, .2)
`
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UserImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
`;

export const EditIcon = styled(MdEdit)`
  cursor: pointer;
  font-size: 24px;
  color: goldenrod;
  transform: scale(.5);
`;
export const AddressEmpty = styled.div`
color: silver;
margin-top: 2rem;
text-align: center;
div {
}

`;

export const Container = styled.div`
/* background-color: teal; */
height: 10vh;
z-index:100;
display: flex;
align-items: flex-start;
justify-content: center;
padding-top: 1rem;
/* width: 900px; */
/* overflow: scroll; */
  @media (min-width: 374px) and (max-width: 768px) {
    width: 400px;
    height: 30vh
    
  }
/* background-color: teal; */

;

`
export const Wrapper = styled.div`
height: 100%;
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;

flex-direction: column;
/* overflow-y: scroll; */
/* background-color: sienna; */


`
export const ImgContainer = styled.div`



`
export const ShoppingContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height: 100%;

/* background-color: teal; */

`
export const AddressContainer = styled.div`
display: flex;
margin-bottom: 1rem;
width: 100%;
align-items: center !important;
margin-top: 1rem;
/* background-color: teal; */

justify-content: space-between;

  @media (min-width: 374px) and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
div {
  flex: 2;
  /* background-color: teal; */
  display: flex;
  align-items: center;


    @media (min-width: 374px) and (max-width: 768px) {
  margin-bottom: 1rem;
  }


}
span {
  flex: 2;
  div {
    display: flex;
    align-items: center !important;
    /* background-color: coral; */
    height: 50px;
    padding-top: 0 !important;
  }
}


`
export const AddressContainerEditing = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 1rem;
width: 100%;
flex: 10;
div {
  flex: 3;
}
span {
  flex: 1;
}


`
export const AddressAddingContainer = styled.div`
display: flex;
margin-bottom: 1rem;
width: 100%;
flex: 10;
flex-direction: column;
/* background-color: teal; */
justify-content: center;
align-items: center;
div {
  flex: 3;
}
span {
  flex: 1;
}
Input {
  width: 90%;
  margin-right: 1rem;
}

  @media (min-width: 374px) and (max-width: 768px) {
    margin-bottom: 0rem;
    margin-top: 2rem;
  }

`
export const AddressesContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 90%;
flex: 10;
margin-top: 1rem;
  animation: ${slideUpAnimation} 1.5s ease;


// background-color: coral; 

  @media (min-width: 374px) and (max-width: 768px) {
    margin-top: 2rem;
  }
span {
}


`
export const ErrorBox = styled.div`
  /* background-color: teal; */
width: 80% ;
margin-top: 1rem;
height: 50px;
margin-top: 2rem;
margin-bottom: 3rem;
border-radius: 5px;
padding: 0.5rem 1rem;
background-color: rgba(255,0,0,0.1);
  @media (min-width: 374px) and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

export const InfoContainer = styled.div`
width: 100%;
height: 100%;
font-weight: 500;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
/* margin-top: 5rem; */

// background-color: purple; 




`
export const Title = styled.div`
margin-right: 1rem;
flex: 1;
margin-top: 7rem;
`
export const Input = styled.input`
flex: 10;
margin-bottom: .5rem;
display: flex;
justify-content: flex-start;
margin-left: 1rem;
border-radius: 5px;
border: 0.5px solid silver;
padding: 0.5rem 1rem;


/* background-color: teal; */

  @media (min-width: 374px) and (max-width: 768px) {
    width: 300px!important;
  }

`
export const Email = styled.div`
margin-left: 1rem;
color: gray;
flex: 10;
`
export const Name = styled.div`

margin-left: 1rem;
flex: 10;
color: gray;
margin-bottom: 2rem;
span {
  margin-bottom: 10px;
    display: inline-block;
}
`
export const EditBtn = styled.button`

  cursor: pointer;
font-weight: bold;
background-color: goldenrod;
border: none;
padding: .3rem .6rem;
color: black;
display: flex;
border-radius: 5px;
justify-content: center;
width: 100px;
height: 30px;
align-items: center;
margin-top: 0 !important;

  @media (min-width: 374px) and (max-width: 768px) {
    padding: .3rem .6rem;
    font-size: .8rem;

  }
&:hover {
  background-color: black;
  color: goldenrod;
}

`
export const DeleteBtn = styled.button`

  cursor: pointer;
font-weight: bold;
background-color: transparent !important;
padding: .2rem .2em;
color: black;
border: none;
display: flex;
border-radius: 5px;
justify-content: center;
width: 20px;
height: 40px;
margin-top: 0 !important;



  @media (min-width: 374px) and (max-width: 768px) {
    padding: .3rem .6rem;
    font-size: .8rem;

  }

`
export const DefaultButton = styled.button`

  cursor: pointer;
font-weight: bold;
background-color: transparent !important;
border: .5px solid  goldenrod!important;
padding: .5rem 1.5rem;
color: black;
display: flex;
align-items: center;
border-radius: 5px;
justify-content: center;
    font-size: .5rem;
width: 100px;
height: 30px;
margin-top: 0 !important;
margin-left: .5rem;
font-size: .8rem;


&:hover {
  background-color: black;
  color: goldenrod;
}

  @media (min-width: 374px) and (max-width: 768px) {
    padding: .3rem .6rem;


  }

`
export const DefaultButtonActive = styled.button`

  cursor: pointer;
font-weight: bold;
background-color: transparent !important;
border: .5px solid transparent !important;
padding: .5rem 1.5rem;
color: black;
display: flex;
align-items: center;
border-radius: 5px;
justify-content: center;
    font-size: .5rem;
margin-left: .5rem;

width: 100px;
height: 30px;
margin-top: 0 !important;
font-size: .8rem;
color: goldenrod;


&:hover {
  background-color: black;
  color: goldenrod;
}

  @media (min-width: 374px) and (max-width: 768px) {
    padding: .3rem .6rem;


  }

`
export const Addbtn = styled.button`
  cursor: pointer;
font-weight: bold;
background-color: goldenrod;
border: none;
padding: 1rem 2rem;
color: black;
width: 90%;
margin-top: 1rem;

background-color: transparent;
border: .5px solid goldenrod;
  animation: ${slideDownAnimation} 1.5s ease;


  @media (min-width: 374px) and (max-width: 768px) {
    padding: .3rem .6rem;
    font-size: .8rem;
    margin-top: 2rem

  }

&:hover {
  background-color: black;
  color: goldenrod;
}

`
export const ResetBtn = styled.button`

  cursor: pointer;
font-weight: bold;
background-color: goldenrod;
border: none;
padding: 1rem 2rem;
color: black;
width: 40%;

&:hover {
  background-color: black;
  color: goldenrod;
}
`
export const SaveBtn = styled.button`

  cursor: pointer;
font-weight: bold;
background-color: goldenrod;
border: none;
padding: 1rem 2rem;
color: black;
width: 90%;
margin-top: .5rem;

  @media (min-width: 374px) and (max-width: 768px) {
    width: 200px;
    font-size: .8rem;
    padding: .5rem 1rem;
  }

&:hover {
  background-color: black;
  color: goldenrod;
}
`