import React, { useState , useEffect}  from 'react'
import { Link } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';


const Account = () => {
  const [LoggedIn, setLoggedIn] = useState(localStorage.getItem('token'));
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    setLoggedIn(null); // Update LoggedIn in the component state
  };
  const handleClick = e => {
    const nextSibling = e.currentTarget.nextSibling;
    if (nextSibling) {
      nextSibling.classList.toggle("active");
    }
  }
  useEffect(() => {
    // Update LoggedIn whenever localStorage changes
    setLoggedIn(localStorage.getItem('token'));
  }, []); // Empty dependency array means this effect runs once on component mount


  return (
    <div>
      <div style={{ display: 'flex' , alignItems: 'center'}}>
        {/* <h4 style={{marginRight: '1rem', marginBottom: '0'}}>Profile</h4> */}

        <button
          onClick={e => handleClick(e)}
          style={{ backgroundColor: 'transparent', border: 'none', transform: 'scale(1.6)', marginLeft: '.45rem' }}
        >
          {LoggedIn !== null &&
            
            <Link to={process.env.PUBLIC_URL + "/my-account"}>

              <i className="pe-7s-user" style={{transform: 'scale(1.4)'}} />

          </Link>
          }
          {LoggedIn === null && <Link to={process.env.PUBLIC_URL + "/login-register"} >

            <i className="pe-7s-user"  />
          </Link>
          }

        </button>

      

      </div>
      <div className="same-style account-setting  " style={{ display: 'flex', alignItems: 'center' , marginTop: '1rem', marginBottom: '1rem'}}>
        {/* <h4 style={{ marginRight: '1rem', marginBottom: '0' }}>Logout</h4> */}

        <button onClick={handleLogout}
          style={{ backgroundColor: 'transparent', border: 'none', transform: 'scale(1.6)' }}
        >
          <Link to={process.env.PUBLIC_URL + "/login-register"} >
            {LoggedIn !== null &&
              <LogoutIcon style={{ transform: 'translateY(-2px) scale(.6)' }} />

            }
          </Link>



        </button>
      </div>
    </div>
  )
}

export default Account