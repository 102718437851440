import PropTypes from "prop-types"
import clsx from "clsx"
import { Link } from "react-router-dom"
import logo from "./logo.png"

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  const goToSite = () => {
    // window.location.href = 'https://www.neoneg.com';
    window.open("https://www.neoneg.com", "_blank")
  }
  const handleWhatsAppClick = () => {
    // Handle WhatsApp link logic here
    window.open(
      "https://api.whatsapp.com/send/?phone=%2B201113588988&text&type=phone_number&app_absent=0",
      "_blank"
    )
  }

  return (
    <div
      className={clsx("copyright", spaceBottomClass, colorClass)}
      style={{ textAlign: "center" }}
    >
      <div className="footer-logo" style={{ marginTop: "0" }}>
        <Link to={process.env.PUBLIC_URL + "/"}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "100px", margin: "5px" }}
          />
        </Link>
      </div>
      <div>
        Copyright
        <br />
        &copy; {new Date().getFullYear()} &nbsp;
        <br />
        <a
          href="https://hasthemes.com"
          rel="noopener noreferrer"
          target="_blank"
        ></a>
        <div onClick={handleWhatsAppClick} style={{ cursor: "pointer" }}>
          By <span onClick={goToSite}>NEON</span>&nbsp;
          <i className="fa fa-whatsapp" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  )
}

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
}

export default FooterCopyright
