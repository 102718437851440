import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import logo from './logo.png';


const Logo = ({ imageUrl, logoClass }) => {
  return (
    <div className={clsx(logoClass)}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        {/* <img alt="" src={process.env.PUBLIC_URL + imageUrl} /> */}
        <img src={logo} alt="logo" style={{width:'60px'}} />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;
