import PropTypes from "prop-types"
import clsx from "clsx"
import SubscribeEmail from "./sub-components/SubscribeEmail"
import { useTranslation } from "react-i18next"

const FooterNewsletter = ({
  spaceBottomClass,
  spaceLeftClass,
  sideMenu,
  colorClass,
  widgetColorClass,
}) => {
  const { t } = useTranslation()
  const lang = localStorage.getItem("languageCode")
    ? localStorage.getItem("languageCode")
    : "en"
  return (
    <>
      {lang === "en" ? (
        <div
          className={clsx(
            "footer-widget",
            spaceBottomClass,
            sideMenu ? "ml-ntv5" : spaceLeftClass,
            widgetColorClass
          )}
        >
          <div className="footer-title">
            <h3>{t("SUBSCRIBE")}</h3>
          </div>
          <div className={clsx("subscribe-style", colorClass)}>
            <p>{t("getUpdates")}</p>
            {/* subscribe email */}
            <SubscribeEmail mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
          </div>
        </div>
      ) : (
        <div
          dir="rtl"
          className={clsx(
            "footer-widget",
            spaceBottomClass,
            sideMenu ? "ml-ntv5" : spaceLeftClass,
            widgetColorClass
          )}
        >
          <div className="footer-title">
            <h3>{t("اشترك")}</h3>
          </div>
          <div className={clsx("subscribe-style", colorClass)}>
            <p>{t("احصل على التحديثات")}</p>
            {/* subscribe email */}
            <SubscribeEmail mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
          </div>
        </div>
      )}
    </>
  )
}

FooterNewsletter.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  colorClass: PropTypes.string,
  widgetColorClass: PropTypes.string,
}

export default FooterNewsletter
