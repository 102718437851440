import { Fragment, useState } from "react"
import { useLocation } from "react-router-dom"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import axios from "axios"

import cogoToast from "cogo-toast"
import { useTranslation } from "react-i18next"

const ForgotNotify = () => {
  let { pathname } = useLocation()

  const { t } = useTranslation()

  return (
    <Fragment>
      <SEO
        titleTemplate="Forgot"
        description="Contact page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: t("home"), path: process.env.PUBLIC_URL + "/" },
            { label: t("verify"), path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        <div className="contact-area pt-100 pb-100">
          <div className="container">
            <div className="custom-row-2" style={{ justifyContent: "center" }}>
              <p>{t("verificationSent")}</p>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  )
}

export default ForgotNotify
